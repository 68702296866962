import React from 'react'
import { AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation } from 'react-router-dom';
import LoginPage from '../pages/auth'
import CategoryPage from '../pages/category';
import ImageGroupPage from '../pages/image/imageGroup';
import ImagePage from '../pages/image/image';
import ProductPage from '../pages/product';
import UserPage from '../pages/user';
import BlogPage from '../pages/blog';
import BrandPage from '../pages/brand';
import ContactPage from '../pages/contact';
import FolderPage from '../pages/folder';
import PagePage from '../pages/page';
import SocialMediaPage from '../pages/socialmedia';
import UnPublicFilePage from '../pages/unpublicfile/unPublicFile';
import SliderPage from '../pages/slideProduct';

const AnimatedRoutes = () => {
    const location = useLocation()
    const token = localStorage.getItem("auth") === null ? null : JSON.parse(localStorage.getItem("auth")).accessToken

    return (
        <AnimatePresence>
            <Routes key={location.pathname} location={location}>
                <Route path='/login' element={token !== undefined || token !== null ? <LoginPage /> : <BlogPage />} />
                <Route path='/' element={token !== null ? <BlogPage /> : <LoginPage />} />
                <Route path='/blog' element={token !== null ? <BlogPage /> : <LoginPage />} />
                <Route path='/brand' element={token !== null ? <BrandPage /> : <LoginPage />} />
                <Route path='/category' element={token !== null ? <CategoryPage /> : <LoginPage />} />
                <Route path='/contact' element={token !== null ? <ContactPage /> : <LoginPage />} />
                <Route path='/folder' element={token !== null ? <FolderPage /> : <LoginPage />} />
                <Route path='/image' element={token !== null ? <ImageGroupPage /> : <LoginPage />} />
                <Route path='/image/:id' element={token !== null ? <ImagePage /> : <LoginPage />} />
                <Route path='/page' element={token !== null ? <PagePage /> : <LoginPage />} />
                <Route path='/product' element={token !== null ? <ProductPage /> : <LoginPage />} />
                <Route path='/slider' element={token !== null ? <SliderPage /> : <LoginPage />} />
                <Route path='/social-media' element={token !== null ? <SocialMediaPage /> : <LoginPage />} />
                <Route path='/unpublic-file' element={token !== null ? <UnPublicFilePage /> : <LoginPage />} />
                <Route path='/unpublic-file/:id' element={token !== null ? <UnPublicFilePage /> : <LoginPage />} />
                <Route path='/user' element={token !== null ? <UserPage /> : <LoginPage />} />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes