import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Edit2, Home, PlusCircle, Trash2 } from "react-feather";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { motion } from "framer-motion";
import {
  Popconfirm,
  Table,
  Select,
  Button as Button2,
  Upload,
  Spin,
} from "antd";
import { toast } from "react-toastify";
import Header from "../../components/header";
import {
  deleteBrand,
  getAllImageGroups,
  getAllBrands,
  getAllCategories,
  getProduct,
  getAllProducts,
  updateProduct,
  createProduct,
  createProductExcelImport,
  getAllBrandsAll,
  getAllCategoriesAll,
  getAllImageGroupsAll,
  getAllUnPublicFiles,
  getAllProductsFilter,
  deleteProduct,
  geAllProductsFilterH,
  updateProductN,
  createProductN,
} from "../../core/request";
import { mobilVariant } from "../../components/animation";
import { useNavigate } from "react-router-dom";
import { splitF } from "../../components/general";

const ProductPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [imageGroups, setImageGroups] = useState([]);
  const [unPublicFiles, setUnPublicFiles] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [multipleLoad, setMultipleLoad] = useState(false);
  const [categories, setCategories] = useState([]);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [excel, setExcel] = useState(null);
  const [brands, setBrands] = useState([]);
  const [fileLoad, setFileLoad] = useState(false);
  const [formData, setFormData] = useState({
    productName: "",
    productNameEN: "",
    shortDescription: "",
    shortDescriptionEN: "",
    content: "",
    contentEN: "",
    price: 0,
    discount: 0,
    discountedPrice: 0,
    additionalSpace1: "",
    additionalSpace1EN: "",
    additionalSpace2: "",
    additionalSpace2EN: "",
    additionalSpace3: "",
    additionalSpace3EN: "",
    barcodeNo: 0,
    lotNo: "",
    refNo: "",
    brandId: null,
    imageGroupID: null,
    categoryId: null,
    unpublicfileId: null,
    category: { value: null, label: "" },
    files:[]
  });

  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loading2, setLoading2] = useState(false);

  const uploadProps = {
    beforeUpload: (file) => {
        const isImage = file.type.startsWith("image/");
        if (!isImage) {
            toast.error("Sadece resim yükleyebilirsiniz");
            return false;
        }
        return false;
    },
    onChange: (info) => {
        const validFiles = info.fileList
            .map((file) => {
                if (file.originFileObj) {
                    return file.originFileObj;
                }
                return file;
            })
            .filter((file) => file);

        setFormData((prev) => ({
            ...prev,
            files: validFiles,
        }));
    },
    multiple: true,
    // listType: "picture",
    defaultFileList: formData.files// eski dosyalar için 
};





  const clearForm = () => {
    setFormData({
      productName: "",
      productNameEN: "",
      shortDescription: "",
      shortDescriptionEN: "",
      content: "",
      contentEN: "",
      price: 0,
      discount: 0,
      discountedPrice: 0,
      additionalSpace1: "",
      additionalSpace1EN: "",
      additionalSpace2: "",
      additionalSpace2EN: "",
      additionalSpace3: "",
      additionalSpace3EN: "",
      barcodeNo: 0,
      lotNo: "",
      refNo: "",
      brandId: null,
      imageGroupID: null,
      unpublicfileId: null,
      categoryId: null,
      files:[]
    });
    setSelectedData(null);
  };

  const addExcell = async () => {
    setFileLoad(true);
    const excelImport = await createProductExcelImport(excel);
    if (excelImport.message === "Success") {
      toast.success("Excel başarıyla içeri aktarıldı.");
      setFileLoad(false);
    } else {
      toast.error("Excel içeri aktarılırken sorun oluştu!");
      setFileLoad(false);
    }
    getDatas();
    setModal(false);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getNewProduct = async (page) => {
    setLoading(false);
    const { data: productData, pagination } = await getAllProducts("", page);
    if (productData.message === "Success") {
      setData(productData.result);
      setPageSize(pagination.PageSize || 10);
      setCurrentPage(pagination.CurrentPage || 1);
      setPagination(pagination);
    }
    setLoading(true);
  };

  

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getDatas = async () => {
    setLoading(false);
    const imageGroups = await getAllImageGroupsAll();
    const categories = await getAllCategories();
    const brands = await getAllBrands();
    const unPublicFiles = await getAllUnPublicFiles("");
    await getNewProduct(1);

    if (imageGroups.message === "Success") {
      var newlist = [];
      for (var i = 0; i < imageGroups.result.length; i++) {
        if (imageGroups.result[i] !== undefined)
          newlist.push({
            value: imageGroups.result[i].imageGroupID,
            label: imageGroups.result[i].title,
          });
      }
      setImageGroups(newlist);
    }
    if (unPublicFiles.message === "Success") {
      var newlist = [];
      for (var i = 0; i < unPublicFiles.result.length; i++) {
        if (unPublicFiles.result[i] !== undefined)
          newlist.push({
            value: unPublicFiles.result[i].unPublicFileID,
            label: unPublicFiles.result[i].additionalField1,
          });
      }
      setUnPublicFiles(newlist);
    }
    if (categories.message === "Success") {
      var newlist = [];
      for (var i = 0; i < categories.result.length; i++) {
        if (categories.result[i] !== undefined)
          newlist.push({
            value: categories.result[i].categoryId,
            label: categories.result[i].name,
          });
      }
      setCategories(newlist);
    }
    if (brands.message === "Success") {
      var newlist = [];
      for (var i = 0; i < brands.result.length; i++) {
        if (brands.result[i] !== undefined)
          newlist.push({
            value: brands.result[i].brandId,
            label: brands.result[i].brandName,
          });
      }
      setBrands(newlist);
    }

    setTimeout(() => {
      setLoading(true);
    }, 1000);
  };

  // const getData = async (id) => {
  //   const brand = await getProduct(id);
  //   setSelectedData(id);
  //   setFormData({
  //     productName: brand.result.productName,
  //     productNameEN: brand.result.productNameEN,
  //     shortDescription: brand.result.shortDescription,
  //     shortDescriptionEN: brand.result.shortDescriptionEN,
  //     content: brand.result.content,
  //     contentEN: brand.result.contentEN,
  //     price: brand.result.price,
  //     discount: brand.result.discount,
  //     discountedPrice: brand.result.discountedPrice,
  //     additionalSpace1: brand.result.additionalSpace1,
  //     additionalSpace1EN: brand.result.additionalSpace1EN,
  //     additionalSpace2: brand.result.additionalSpace2,
  //     additionalSpace2EN: brand.result.additionalSpace2EN,
  //     additionalSpace3: brand.result.additionalSpace3,
  //     additionalSpace3EN: brand.result.additionalSpace3EN,
  //     barcodeNo: brand.result.barcodeNo,
  //     lotNo: brand.result.lotNo,
  //     refNo: brand.result.refNo,
  //     brandId: brand.result.brandId,
  //     unpublicfileId: brand.result.unPublicFileId,
  //     imageGroupID: brand.result.imageGroupID,
  //     categoryId: brand.result.categoryId,
  //     category: {
  //       value: brand.result.categoryId,
  //       label: brand.result.category.name,
  //     },
  //     brandName: brand.result.brandName,
  //   });
  // };
  const getData = async (id) => {
    const product = await getProduct(id);
    if (product?.result) {
        setSelectedData(id);
        setFormData({
            ...formData,
            productName: product.result.productName || "",
            // ... diğer alanlar
            files: product.result.files || [], // Mevcut dosyaları ekle
        });
    }
};



  const addData = async () => {
    let res;

    const files=formData.files || [];
    if (selectedData) {
      res = await updateProductN(
        selectedData,
        formData.productName,
        formData.productNameEN,
        formData.shortDescription,
        formData.shortDescriptionEN,
        formData.content,
        formData.contentEN,
        formData.price,
        formData.discount,
        formData.discountedPrice,
        formData.additionalSpace1,
        formData.additionalSpace1EN,
        formData.additionalSpace2,
        formData.additionalSpace2EN,
        formData.additionalSpace3,
        formData.additionalSpace3EN,
        formData.barcodeNo,
        formData.lotNo,
        formData.refNo,
        formData.brandId,
        formData.imageGroupID,
        formData.unpublicfileId,
        formData.categoryId,
        false,//isSlider
        files
      );
    } else {
      res = await createProductN(
        formData.productName,
        formData.productNameEN,
        formData.shortDescription,
        formData.shortDescriptionEN,
        formData.content,
        formData.contentEN,
        formData.price,
        formData.discount,
        formData.discountedPrice,
        formData.additionalSpace1,
        formData.additionalSpace1EN,
        formData.additionalSpace2,
        formData.additionalSpace2EN,
        formData.additionalSpace3,
        formData.additionalSpace3EN,
        formData.barcodeNo,
        formData.lotNo,
        formData.refNo,
        formData.brandId,
        formData.imageGroupID,
        formData.unpublicfileId,
        formData.categoryId,
        files
      );
    }
    if (res.message === "Success") toast.success("Ürün içeriği eklendi.");
    else toast.error("Ürün içeriği eklenirken bir sorun oluştu!");
    getDatas();
    clearForm();
  };

  const deleteData = async (id) => {
    const brand = await deleteProduct(id);
    if (brand.message === "Success") toast.success("Ürün içeriği silindi.");
    else toast.error("Ürün içeriği silinirken bir sorun oluştu!");
    clearForm();
    getDatas();
  };

  const multipleDelete = async () => {
    setMultipleLoad(true);
    if (selectedRowKeys.length > 0) {
      for (var i = 0; i < selectedRowKeys.length; i++) {
        await deleteProduct(selectedRowKeys[i]);
      }
      await getDatas();
    }
    setMultipleLoad(false);
  };

  //serach işlemi için

  const handleSearch = async (page=1,size=10) => {
    setLoading2(true);
    try {
      // console.log("Search Text:", searchText);
      const { data: result,pagination: apiPagination } = await geAllProductsFilterH(searchText, page, size);
      console.log("API:", pagination);
      setPageSize(apiPagination.PageSize || size);
      setCurrentPage(apiPagination.CurrentPage || page);
      setPagination(apiPagination);
      if (result?.message === "Success") {
        setFilteredData(result?.result || []);
        console.log("Filtered Data:", result.result);
      } else {
        toast.error("Ürünler getirilirken bir sorun oluştu!");
      }
    } catch (error) {
      toast.error("Ürünler getirilirken bir hata oluştu: " + error.message);
    }
    // setSearchText(""); 
    setLoading2(false);
  };

  const columns = [
    {
      title: "ID",
      key: "productId",
      width: 30,
      dataIndex: "productId",
    },
    {
      title: "Başlık",
      key: "productName",
      minWidth: 100,
      render: (e) => splitF(e.productName, 40),
      sorter: (a, b) => a.productName.localeCompare(b.productName),
    },
    {
      title: "Açıklamalar",
      key: "shortDescription",
      minWidth: 100,
      render: (e) => splitF(e.shortDescription, 50),
      sorter: (a, b) => a.shortDescription.localeCompare(b.shortDescription),
    },
    {
      title: "İşlemler",
      width: 30,
      render: (e) => (
        <div className="d-flex justify-content-start">
          <div>
            <Button2
              className="border-warning text-warning"
              icon={<Edit2 size={20} />}
              onClick={() => getData(e.productId)}
            />
          </div>
          <div className="ml-2">
            <Popconfirm
              title="Bu ürün içeriğini silmek istediğinizden emin misiniz?"
              onConfirm={() => deleteData(e.productId)}
              okText="Evet"
              cancelText="Hayır"
            >
              <Button2 icon={<Trash2 size={20} />} danger />
            </Popconfirm>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (searchText) {
      handleSearch(currentPage, pageSize || 10);
    } else {
      getNewProduct(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    getDatas();
  }, []);
 

  return (
    <motion.div {...mobilVariant}>
      <Row className="w-100 pb-5">
        <Col sm={3} md={3} xl={3}>
          <Header />
        </Col>
        <Col sm={9} md={9} xl={9}>
          <div className="right-content mb-5">
            <div className="d-flex justify-content-between">
              <div>
                <h3>Ürün Yönetimi</h3>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  color="info"
                  className="ml-2"
                  onClick={() => navigate("/image")}
                >
                  <PlusCircle
                    size={20}
                    style={{ marginTop: -3, marginRight: 7 }}
                  />
                  Resim Grubu Ekle
                </Button>
                <Button
                  color="info"
                  className="ml-2"
                  onClick={() => navigate("/brand")}
                >
                  <PlusCircle
                    size={20}
                    style={{ marginTop: -3, marginRight: 7 }}
                  />
                  Marka Ekle
                </Button>
                <Button
                  color="info"
                  className="ml-2"
                  onClick={() => navigate("/category")}
                >
                  <PlusCircle
                    size={20}
                    style={{ marginTop: -3, marginRight: 7 }}
                  />
                  Kategori Ekle
                </Button>
                <Button
                  onClick={() => navigate("/")}
                  className="ml-2 bg-white border border-info text-info"
                >
                  <Home size={20} style={{ marginTop: -5 }} /> Dashboard
                </Button>
              </div>
            </div>
            <Card className="shadow mt-3">
              <CardHeader className="pl-4 shadow-sm d-flex justify-content-between align-items-center bg-white p-3">
                <div>
                  <h4>Ürün {selectedData ? "Güncelle" : "Ekle"}</h4>
                </div>
              </CardHeader>
              <CardBody>
                <Row className="p-1">
                  <Col md={4} className="mb-3">
                    <span>Ürün Adı</span>
                    <Input
                      type="input"
                      value={formData.productName}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          productName: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <span>Ürün Adı (EN)</span>
                    <Input
                      type="input"
                      value={formData.productNameEN}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          productNameEN: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <span>Açıklama</span>
                    <Input
                      type="input"
                      value={formData.shortDescription}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          shortDescription: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <span>Açıklama (EN)</span>
                    <Input
                      type="input"
                      value={formData.shortDescriptionEN}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          shortDescriptionEN: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <span>İçerik</span>
                    <Input
                      type="input"
                      value={formData.content}
                      onChange={(e) =>
                        setFormData({ ...formData, content: e.target.value })
                      }
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <span>İçerik (EN)</span>
                    <Input
                      type="input"
                      value={formData.contentEN}
                      onChange={(e) =>
                        setFormData({ ...formData, contentEN: e.target.value })
                      }
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <span>Ambalaj</span>
                    <Input
                      type="input"
                      value={formData.additionalSpace1}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          additionalSpace1: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <span>Ambalaj (EN)</span>
                    <Input
                      type="input"
                      value={formData.additionalSpace1EN}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          additionalSpace1EN: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <span>Kısa Açıklama</span>
                    <Input
                      type="input"
                      value={formData.additionalSpace2}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          additionalSpace2: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <span>Kısa Açıklama (EN)</span>
                    <Input
                      type="input"
                      value={formData.additionalSpace2EN}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          additionalSpace2EN: e.target.value,
                        })
                      }
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <span>Barkod</span>
                    <Input
                      type="input"
                      value={formData.barcodeNo}
                      onChange={(e) =>
                        setFormData({ ...formData, barcodeNo: e.target.value })
                      }
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <span>Lot No</span>
                    <Input
                      type="input"
                      value={formData.lotNo}
                      onChange={(e) =>
                        setFormData({ ...formData, lotNo: e.target.value })
                      }
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <span>Ref No</span>
                    <Input
                      type="input"
                      value={formData.refNo}
                      onChange={(e) =>
                        setFormData({ ...formData, refNo: e.target.value })
                      }
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <span>Marka</span>
                    <Select
                      options={brands}
                      className="w-100"
                      style={{ height: 35 }}
                      value={formData.brandId}
                      onChange={(e) => setFormData({ ...formData, brandId: e })}
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                   <span className="m-1">Dosya Ekle</span>
                    <Upload {...uploadProps}>
                  <Button icon={<UploadOutlined />}>Dosya Ekle</Button>
                   </Upload>
                   </Col>
                  <Col md={4} className="mb-3">
                    <span>Kategori</span>
                    <Select
                      options={categories}
                      className="w-100"
                      style={{ height: 35 }}
                      value={formData.category}
                      onChange={(e, i) =>
                        setFormData({
                          ...formData,
                          categoryId: e,
                          category: { value: e, label: i.label },
                        })
                      }
                    />
                  </Col>
                  <Col md={4} className="mb-3">
                    <span>Gizli Dosya</span>
                    <Select
                      options={unPublicFiles}
                      className="w-100"
                      style={{ height: 35 }}
                      value={formData.unpublicfileId}
                      onChange={(e, i) =>
                        setFormData({ ...formData, unpublicfileId: e })
                      }
                    />
                  </Col>
                  <Col md={4}>
    {formData.files && formData.files.length > 0 && (
        <div style={{ 
            padding: '10px',
            border: '1px solid #e8e8e8',
            borderRadius: '4px',
            marginTop: '10px'
        }}>
            <h6>Mevcut Dosyalar:</h6>
            {formData.files.map((file, index) => (
                <div 
                    key={index}
                    style={{
                        padding: '5px',
                        margin: '5px 0',
                        background: '#f5f5f5',
                        borderRadius: '4px'
                    }}
                >
                    {typeof file === 'string' ? file.split('/').pop() : file.name}
                </div>
            ))}
        </div>
    )}
</Col>
                  <Col md={12} className="mt-3 d-flex justify-content-end">
                    {selectedData ? (
                      <Button
                        onClick={() => clearForm()}
                        className="bg-white border-warning text-warning mr-1"
                      >
                        Temizle
                      </Button>
                    ) : null}
                    <Button
                      color={selectedData ? "warning" : "success"}
                      onClick={() => addData()}
                    >
                      <PlusCircle
                        size={20}
                        style={{ marginTop: -3, marginRight: 7 }}
                      />
                      {selectedData ? "Güncelle" : "Ekle"}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="shadow mt-4">
              <CardHeader className="pl-4 d-flex shadow-sm justify-content-between align-items-center bg-white p-3">
                <div>
                  <h4>Ürün Listesi</h4>
                </div>
                {selectedRowKeys.length > 0 ? (
                  <div>
                    <Button
                      onClick={multipleDelete}
                      className="d-flex justify-content-center text-white"
                      color="danger"
                    >
                      <Trash2
                        size={20}
                        style={{ marginTop: 3, marginRight: 7 }}
                      />{" "}
                      {multipleLoad ? (
                        <Spinner
                          style={{ width: 23, height: 23 }}
                          color="white"
                        >
                          {" "}
                        </Spinner>
                      ) : (
                        "Toplu Sil"
                      )}
                    </Button>
                  </div>
                ) : null}
                <div className="d-flex align-items-center">
                  <Input
                    className="filter-search border border-success "
                    size="large"
                    placeholder="Ürün Ara..."
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                  <Button
                    color="border border-success bg-transparent text-success filter-button"
                    onClick={() => handleSearch(1)}
                    className="ml-1"
                    size="large"
                  >
                    {loading2 ? <Spin size="sm" /> : "Ara"}
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => setModal(true)}
                    className="border border-success bg-transparent text-success"
                  >
                    <PlusCircle
                      size={20}
                      style={{ marginTop: -5, marginRight: 7 }}
                    />
                    Excel Import
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <Table
                    rowKey="productId"
                    dataSource={filteredData.length > 0 ? filteredData : data}
                    rowSelection={rowSelection}
                    loading={loading2}
                    columns={columns}
                    scroll={{ x: "max-content" }}
                    pagination={{
                      total: pagination?.TotalCount || 0,
                      pageSize: pageSize || 10,
                      current: currentPage || 1,
                      showSizeChanger: true,
                      pageSizeOptions: ['10', '20', '25', '50'],
                      onChange: (page, size) => {                          
                          setCurrentPage(page);
                          setPageSize(size);
                          if (filteredData.length > 0) {
                            handleSearch(page, size);
                          } else {
                            getNewProduct(page, size); 
                          };
                      },
                      onShowSizeChange: (current, size) => {
                          setPageSize(size);
                          setCurrentPage(1);
                          if (filteredData.length > 0) {
                            setFilteredData([]);
                            handleSearch(1, size);
                          } else {
                            getNewProduct(1, size);
                          }
                      }
                    }}
                  />
                ) : (
                  <div className="w-100 mt-5 mb-5 d-flex justify-content-center align-items-center">
                    <Spinner
                      style={{ width: "3rem", height: "3rem" }}
                      color="info"
                    >
                      {" "}
                    </Spinner>
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>

      <Modal
        isOpen={modal}
        toggle={() => setModal(!modal)}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={() => setModal(!modal)}>Excel Import</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <Input
                type="file"
                onChange={(e) => setExcel(e.target.files[0])}
              />
            </Col>
            <Col md={6}>
              <Button
                className="w-100"
                color="success"
                onClick={() => addExcell()}
              >
                {fileLoad ? (
                  <Spinner style={{ width: 30, height: 30 }} color="info">
                    {" "}
                  </Spinner>
                ) : (
                  "Ekle"
                )}
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </motion.div>
  );
};

export default ProductPage;
