import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Input, Row, Spinner } from 'reactstrap';
import { motion } from 'framer-motion';
import { Home, Trash2 } from 'react-feather';
import { toast } from 'react-toastify';
import Header from '../../components/header';
import { createSocialMedia, deleteSocialMedia, getAllSocialMedias } from '../../core/request';
import { mobilVariant } from '../../components/animation';
import { useNavigate } from 'react-router-dom';
import { Popconfirm, Button as Button2, Table } from 'antd';

const SocialMediaPage = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        file: null,
        filePath: "",
        SocialMediaTitle: "",
        SocialMediaUrl: ""
    })

    const clearForm = () => {
        setFormData({ file: null, filePath: "", SocialMediaTitle: "", SocialMediaUrl: "" })
    }

    const getDatas = async () => {
        const images = await getAllSocialMedias();
        if (images.message === "Success")
            setData(images.result);
        setTimeout(() => {
            setLoading(true)
        }, 1000);
    }

    const columns = [
        {
            title: "ID",
            key: "socialMediaId",
            dataIndex: "socialMediaId"
        },
        {
            title: "Sosyal Medya",
            key: "socialMediaTitle",
            render: (e) => e.socialMediaTitle
        },
        {
            title: "URL",
            key: "socialMediaUrl",
            render: (e) => e.socialMediaUrl
        },
        {
            title: "İşlemler",
            render: (e) => (
                <div className='d-flex justify-content-start'>
                    <div className='ml-2'>
                        <Popconfirm
                            title="Bu sosyal medya içeriğini silmek istediğinizden emin misiniz?"
                            onConfirm={() => deleteData(e.socialMediaId)}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <Button2 icon={<Trash2 size={20} />} danger />
                        </Popconfirm>
                    </div>
                </div>
            )
        }
    ];

    const addData = async () => {
        let res = await createSocialMedia(formData.file, formData.SocialMediaTitle, formData.SocialMediaUrl)

        if (res.message === "Success")
            toast.success("Sosyal medya eklendi.")
        else
            toast.error("Sosyal medya eklenirken bir sorun oluştu!")
        clearForm()
        getDatas()
    }

    const deleteData = async (id) => {
        const image = await deleteSocialMedia(id);
        if (image.message === "Success")
            toast.success("Sosyal medya içeriği silindi.")
        else
            toast.error("Sosyal medya içeriği silinirken bir sorun oluştu!")
        getDatas()
    }

    useEffect(() => {
        getDatas();
    }, []);

    return (
        <motion.div {...mobilVariant}>
            <Row className='w-100 pb-5'>
                <Col sm={3} md={3} xl={3}>
                    <Header />
                </Col>
                <Col sm={9} md={9} xl={9}>
                    <div className='right-content mb-5'>
                        <div className='w-100 d-flex justify-content-end mb-3'>
                            <div className='d-flex justify-content-end'>
                                <Button onClick={() => navigate("/")} className='ml-2 bg-white border border-info text-info'><Home size={20} style={{ marginTop: -5 }} /> Dashboard</Button>
                            </div>
                        </div>
                        <Card>
                            <CardHeader className='pl-4 d-flex shadow-sm justify-content-between align-items-center bg-white p-3'>
                                <div><h4>Sosyal Medya Ekle</h4></div>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={4} className='mb-3'>
                                        <span>Sosyal Medya Adı</span>
                                        <Input
                                            type='file'
                                            onChange={e =>
                                                setFormData({ ...formData, file: e.target.files[0] })
                                            }
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Sosyal Medya Adı</span>
                                        <Input
                                            type='input'
                                            value={formData.SocialMediaTitle}
                                            onChange={e =>
                                                setFormData({ ...formData, SocialMediaTitle: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Sosyal Medya URL</span>
                                        <Input
                                            type='input'
                                            value={formData.SocialMediaUrl}
                                            onChange={e =>
                                                setFormData({ ...formData, SocialMediaUrl: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <div className='d-flex justify-content-end'>
                                            <Button onClick={() => addData()} color="success">Ekle</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='shadow mt-4'>
                            <CardHeader className='pl-4 d-flex shadow-sm justify-content-between align-items-center bg-white p-3'>
                                <div><h4>Sosyal Medya Listesi</h4></div>
                            </CardHeader>
                            <CardBody>
                                {loading ? <Table
                                    rowKey='socialMediaId'
                                    dataSource={data}
                                    columns={columns}
                                /> : <div className='w-100 mt-5 mb-5 d-flex justify-content-center align-items-center'>
                                    <Spinner style={{ width: '3rem', height: '3rem' }} color='info'> </Spinner>
                                </div>}
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </motion.div>
    );
};

export default SocialMediaPage;
