import React, { useState } from 'react'
import { Menu, Home, User, Globe, LogOut, MessageSquare, AlignCenter, Aperture, Mail, Settings, Star, Truck, Image, Grid, List, Users, BookOpen, Archive, Command, Folder, CreditCard, Linkedin, Shield, Sliders } from 'react-feather'
import { Link, NavLink } from 'react-router-dom'
import { toast } from 'react-toastify'
import logo from '../image/logo.png'

const Header = () => {
    const [openMobil, setOpenMobil] = useState(false)

    return (
        <>
            <div className='navbarheader overflow-auto shadow p-3 mb-5'>
                <div className='logo'>
                    <div><img src={logo} alt='' style={{ height: 40 }} /></div>
                </div>
                <div className='down'>
                    <div className='baslik'><small>Menüler</small></div>
                    <div className='menus'>
                        <div><NavLink to='/blog' activeClassName='active'><BookOpen /><span>Blog Yönetimi</span></NavLink></div>
                        <div><NavLink to='/brand'><Archive /><span>Marka Yönetimi</span></NavLink></div>
                        <div><NavLink to='/category'><Command /><span>Kategori Yönetimi</span></NavLink></div>
                        <div><NavLink to='/contact'><Mail /><span>İletişim Yönetimi</span></NavLink></div>
                        {/* <div><NavLink to='/folder'><Folder /><span>Döküman Yönetimi</span></NavLink></div> */}
                        <div><NavLink to='/image'><Image /><span>Resim Yönetimi</span></NavLink></div>
                        <div><NavLink to='/page'><CreditCard /><span>Sayfa Yönetimi</span></NavLink></div>
                        <div><NavLink to='/product'><Grid /><span>Ürün Yönetimi</span></NavLink></div>
                        <div><NavLink to='/slider'><Grid /><span>Slider Yönetimi</span></NavLink></div>
                        {/* <div><Link to='/social-media'><Linkedin /><span>Sosyal Medya Yönetimi</span></Link></div> */}
                        <div><NavLink to='/unpublic-file'><Shield /><span>Gizli Dosya Yönetimi</span></NavLink></div>
                    </div>
                    <div className='baslik'><small>Ayarlar</small></div>
                    <div className='menus border-0'>
                        <div><NavLink to='/user'><Users /><span>Kullanıcı Yönetimi</span></NavLink></div>
                        {/* <div><Link to='/setting'><User /><span>Ayarlar</span></Link></div> */}
                        <div><a target="_blank" href='https://www.liberyus.com/'><Globe /><span>Siteye Git</span></a></div>
                        {/* <div><a href='https://www.liberyus.com/'><Smartphone /><span>Uygulamaya Git</span></a></div> */}
                        <div><a onClick={() => {
                            localStorage.clear();
                            toast.success('Çıkış Başarılı.')
                            setTimeout(() => {
                                window.location.href = '/login'
                            }, 1000)
                        }}><LogOut /><span>Çıkış Yap</span></a></div>
                    </div>
                </div>
                <div className='template text-center mb-4'>
                    <div><span>Tüm Hakları Saklıdır.</span></div>
                    <div><a href='https://www.liberyus.com/'>Liberyus</a></div>
                </div>
            </div>

            <div className='mobil-menu'>
                <div><img src={logo} alt='' style={{ height: 40 }} /></div>
                <div onClick={() => { setOpenMobil(!openMobil) }}><Menu /></div>
            </div>

            {
                openMobil ? <div className='mobil-menu-view'>
                    <div className='logo'>
                        <div><h2>Admin Panel</h2></div>
                        <div onClick={() => { setOpenMobil(!openMobil) }}><Menu /></div>
                    </div>
                    <div className='down'>
                        <div className='baslik'><small>Menüler</small></div>
                        <div className='menus'>
                            <div><Link to='/blog'><BookOpen /><span>Blog Yönetimi</span></Link></div>
                            <div><Link to='/brand'><Archive /><span>Marka Yönetimi</span></Link></div>
                            <div><Link to='/category'><Command /><span>Kategori Yönetimi</span></Link></div>
                            <div><Link to='/contact'><Mail /><span>İletişim Yönetimi</span></Link></div>
                            {/* <div><Link to='/folder'><Folder /><span>Dosya Yönetimi</span></Link></div> */}
                            <div><Link to='/image'><Image /><span>Resim Yönetimi</span></Link></div>
                            <div><Link to='/page'><CreditCard /><span>Sayfa Yönetimi</span></Link></div>
                            <div><Link to='/slider'><Sliders /><span>Slider Yönetimi</span></Link></div>
                            <div><Link to='/product'><Grid /><span>Ürün Yönetimi</span></Link></div>
                            {/* <div><Link to='/social-media'><Linkedin /><span>Sosyal Medya Yönetimi</span></Link></div> */}
                            <div><Link to='/unpublic-file'><Shield /><span>Gizli Dosya Yönetimi</span></Link></div>
                        </div>
                        <div className='baslik'><small>Ayarlar</small></div>
                        <div className='menus border-0'>
                            <div><Link to='/user'><Users /><span>Kullanıcı Yönetimi</span></Link></div>
                            {/* <div><Link to='/setting'><User /><span>Ayarlar</span></Link></div> */}
                            <div><a target="_blank" href='https://alkim.test.liberyus.com//'><Globe /><span>Siteye Git</span></a></div>
                            {/* <div><a href='https://www.liberyus.com/'><Smartphone /><span>Uygulamaya Git</span></a></div> */}
                            <div><a onClick={() => {
                                localStorage.clear();
                                toast.success('Çıkış Başarılı.')
                                setTimeout(() => {
                                    window.location.href = '/login'
                                }, 1000)
                            }}><LogOut /><span>Çıkış Yap</span></a></div>
                        </div>
                        <div className='template text-center mb-4'>
                            <div><span>Tüm Hakları Saklıdır.</span></div>
                            <div><a href='https://www.liberyus.com/'>Liberyus</a></div>
                        </div>
                    </div>
                </div> : null
            }
        </>
    )
}

export default Header
