import React, { useEffect, useRef, useState } from 'react';
import { Edit2, Home, PlusCircle, Trash2 } from 'react-feather';
import { Button, Card, CardBody, CardHeader, Col, Input, Row, Spinner } from 'reactstrap';
import { motion } from 'framer-motion';
import { Popconfirm, Table, Select, Button as Button2, Spin } from 'antd';
import { toast } from 'react-toastify';
import Header from '../../components/header';
import { createCategory, deleteCategory, getCategory, updateCategory, getAllCategories, getAllImageGroupsAll, getCategoriesParams, sortCategories } from '../../core/request';
import { mobilVariant } from '../../components/animation';
import { useNavigate } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// sürrükle bırak yöntemi için 
const DraggableBodyRow=({ index, moveRow, className, style, ...restProps })=> {
    const ref = useRef();

    const [{isDragging}, drag] = useDrag({
        type: 'draggableRow',
        item: { index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    // Hedef satır bırakıldığında moveRow'u çalıştırma
    const [, drop] = useDrop({
        accept: 'draggableRow',
        drop: (item) => {
            if (item.index !== index) {
              moveRow(item.index, index); // Bırakma anında çalışır
              item.index = index;
            }
          }, 
          collect: (monitor) => ({
            isOver: monitor.isOver()
          })
    });
    // Satırı hem sürüklenebilir hem bırakılabilir hale getirmek için
    drag(drop(ref));
    return (
        <tr ref={ref} className={`draggable-row ${className}`} style={{...style,opacity:isDragging?0.5:1}} {...restProps} />
    );

}



const CategoryPage = () => {
    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState(null)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [multipleLoad, setMultipleLoad] = useState(false)
    const [imageGroups, setImageGroups] = useState([]);
    const [loading, setLoading] = useState(false)
    const [categories, setCategories] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        nameEN: "",
        content: "",
        contentEN: "",
        imageGroupID: null,
        parentCategoryId: null,
    });
        const [searchText, setSearchText] = useState("");
        const [filteredData, setFilteredData] = useState([]);
        const [loading2, setLoading2] = useState(false);

        //serach işlemi için
        
            const handleSearch = async () => {
                setLoading2(true);
                try {
                    console.log("Search Text:", searchText);
                    const result = await getCategoriesParams(searchText);
                    console.log("API Result:", result)
                    if (result?.message === "Success") {
                        const searchResult=result.result? [result.result]:[];
                        setFilteredData(searchResult);
                        console.log("Filtered Data:", result.result);
                    
                        
                    } else {
                        toast.error("Kategori ismini lütfen  doğru giriniz!");
                    }
                } catch (error) {
                    toast.error("Ürünler getirilirken bir hata oluştu: " + error.message);
                }
                setSearchText(""); // Input alanının içeriğini temizleyin
                setLoading2(false);
            };  


            //kullanıcı filtreleme sonrası rest yapmak istediğinde 
            const resetCategories=async()=>{
                try {
                    setLoading2(true);
                    await getDatas();
                    setFilteredData([]);
                    setSearchText("");
                    toast.success("Kategoriler başarıyla getirildi!");
                    
                                 
                } catch (error) {
                    toast.error("Kategoriler getirilirken bir hata oluştu: " + error.message);                    
                }
                setLoading2(false);            }

    const clearForm = () => {
        setFormData({ name: "", nameEN: "", content: "", contentEN: "", imageGroupID: null, parentCategoryId: null })
        setSelectedData(null)
    }

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const getDatas = async () => {
        const imageGroups = await getAllImageGroupsAll();
        const categories = await getAllCategories();
        if (categories.message === "Success"){
            let categoryPage=categories.result;

            categoryPage.sort((a,b)=>{
                if (a.sort === null && b.sort === null) return 0;
                if (a.sort === null) return 1;
                if (b.sort === null) return -1;
                return a.sort - b.sort;
            });

        
        setData(categoryPage);
        setFilteredData([]);
    }     

        if (imageGroups.message === "Success") {
            var newlist = []
            for (var i = 0; i < imageGroups.result.length; i++) {
                if (imageGroups.result[i] !== undefined)
                    newlist.push({ value: imageGroups.result[i].imageGroupID, label: imageGroups.result[i].title });
            }
            setImageGroups(newlist);
        }
        if (categories.message === "Success") {
            var newlist = []
            for (var i = 0; i < categories.result.length; i++) {
                if (categories.result[i] !== undefined)
                    newlist.push({ value: categories.result[i].categoryId, label: categories.result[i].name });
            }
            setCategories(newlist);
        }
        setTimeout(() => {
            setLoading(true)
        }, 1000);
    };

    const getData = async (id) => {
        const category = await getCategory(id);
        setSelectedData(id)
        setFormData({ name: category.result.name, nameEN: category.result.nameEN, imageGroupID: category.result.imageGroupID, parentCategoryId: category.result.parentCategoryId })
    }

    const addData = async () => {
        let res;
        if (selectedData) {
            res = await updateCategory(selectedData, formData.name, formData.nameEN, formData.imageGroupID, formData.parentCategoryId);
        } else {
            res = await createCategory(formData.name, formData.nameEN, formData.imageGroupID, formData.parentCategoryId);
        }
        if (res.message === "Success")
            toast.success("Kategori içeriği eklendi.")
        else
            toast.error("Kategori içeriği eklenirken bir sorun oluştu!")
        getDatas()
        clearForm()
    }

    const deleteData = async (id) => {
        await deleteCategory(id);
        toast.success("Kategori içeriği silindi.")
        clearForm()
        getDatas()
    }

    const multipleDelete = async () => {
        setMultipleLoad(true)
        if (selectedRowKeys.length > 0) {
            for (var i = 0; i < selectedRowKeys.length; i++) {
                await deleteCategory(selectedRowKeys[i]);
            }
            await getDatas()
        }
        setMultipleLoad(false)
    }

    const [columns, setColumns] = useState([
        {
            title: "ID",
            key: "categoryId",
            dataIndex: "categoryId",
            width: 30,
        },
        {
            title: "Başlık",
            key: "name",
            minWidth: 50,
            render: (e) => e.name,
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: "Resim Grubu",
            key: "imageGroupID",
            minWidth: 30,
            render: (e) => e.imageGroup === null ? "" : e.imageGroup.title
        },
        // {
        //     title: "Description",
        //     key: "shortDescription",
        //     render: (e) => e.shortDescription
        // },
        {
            title: "İşlemler",
            width: 50,
            render: (e) => (
                <div className='d-flex justify-content-start'>
                    <div>
                        <Button2 className='border-warning text-warning' icon={<Edit2 size={20} />} onClick={() => getData(e.categoryId)} />
                    </div>
                    <div className='ml-2'>
                        <Popconfirm
                            title="Bu kategori içeriğini silmek istediğinizden emin misiniz?"
                            onConfirm={() => deleteData(e.categoryId)}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <Button2 icon={<Trash2 size={20} />} danger />
                        </Popconfirm>
                    </div>
                </div>
            )
        }
    ]);

    //sürükle bırak işlemi için

    const moveRow = async(dragIndex, hoverIndex) => {
        const dragRow = data[dragIndex];
        const newData = [...data];
        newData.splice(dragIndex, 1);       
        newData.splice(hoverIndex, 0, dragRow);
        

        try {
           

            const result=await sortCategories(dragRow.categoryId,hoverIndex);
            console.log('Sort result:', result);
            if(result.message==="Success"){
                await getDatas();
                toast.success("Kategoriler sıralandı!");
            }else {                
                toast.error("Kategoriler sıralanırken bir hata oluştu!");  
                 getDatas(); 
         }
            
        } catch (error) {
            console.log('Sort error:', error);
            toast.error("Kategorilerde hata oluştu: "+error.message);
            getDatas();
            
        }
        
    };





    useEffect(() => {
        getDatas();
    }, []);

    return (
        <DndProvider backend={HTML5Backend}>
        <motion.div {...mobilVariant}>
            <Row className='w-100 pb-5'>
                <Col sm={3} md={3} xl={3}>
                    <Header />
                </Col>
                <Col sm={9} md={9} xl={9}>
                    <div className='right-content mb-5'>
                        <div className='d-flex justify-content-between'>
                            <div><h3>Kategori Yönetimi</h3></div>
                            <div className='d-flex justify-content-end'>
                                <Button color='info' onClick={() => navigate("/image")}><PlusCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />Resim Grubu Ekle</Button>
                                <Button onClick={() => navigate("/")} className='ml-2 bg-white border border-info text-info'><Home size={20} style={{ marginTop: -5 }} /> Dashboard</Button>
                            </div>
                        </div>
                        <Card className='shadow mt-3'>
                            <CardHeader className='pl-4 shadow-sm d-flex justify-content-between align-items-center bg-white p-3' tag='h4'>Kategori Panosu Ekle</CardHeader>
                            <CardBody>
                                <Row className='p-1'>
                                    <Col md={6} className='mb-3'>
                                        <span>Kategori Adı</span>
                                        <Input
                                            type='input'
                                            value={formData.name}
                                            onChange={e =>
                                                setFormData({ ...formData, name: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Kategori Adı (EN)</span>
                                        <Input type='input'
                                            value={formData.nameEN}
                                            onChange={e =>
                                                setFormData({ ...formData, nameEN: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Resim Grubu</span>
                                        <Select
                                            options={imageGroups}
                                            className='w-100'
                                            style={{ height: 35 }}
                                            value={formData.imageGroupID}
                                            onChange={e => setFormData({ ...formData, imageGroupID: e })}
                                        />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <span>Üst Kategori</span>
                                        <Select
                                            options={categories}
                                            className='w-100'
                                            style={{ height: 35 }}
                                            value={formData.parentCategoryId}
                                            onChange={e => setFormData({ ...formData, parentCategoryId: e })}
                                        />
                                    </Col>
                                    <Col md={12} className='mt-3 d-flex justify-content-end'>
                                        {selectedData ? <Button onClick={() => clearForm()} className='bg-white border-warning text-warning mr-1'>Temizle</Button> : null}
                                        <Button
                                            color={selectedData ? 'warning' : 'success'}
                                            onClick={() => addData()}
                                        >
                                            <PlusCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />{selectedData ? "Güncelle" : "Ekle"}
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='shadow mt-4'>
                            <CardHeader className='pl-4 d-flex shadow-sm justify-content-between align-items-center bg-white p-3'>
                                <div><h4>Kategori Listesi</h4></div>
                                {selectedRowKeys.length > 0 ? <div><Button onClick={multipleDelete} className='d-flex justify-content-center text-white' color='danger'><Trash2 size={20} style={{ marginTop: 3, marginRight: 7 }} /> {multipleLoad ? <Spinner style={{ width: 23, height: 23 }} color='white'> </Spinner> : "Toplu Sil"}</Button></div> : null}
                                <Button
            color='border border-info bg-transparent text-info filter-button'
            onClick={resetCategories}
            className='ml-1'
            size="large"
            disabled={loading2}
        >
            {loading2 ? <Spin size="sm" /> : "Tümü"}
        </Button>
                                <div className='d-flex align-items-center'>    
                                                                   <Input className='filter-search border border-success '
                                                                      size="large"
                                                                       placeholder="Kategori Ara"
                                                                       value={searchText}
                                                                       onChange={e => setSearchText(e.target.value)}
                                                                   />
                                                                    <Button color='border border-success bg-transparent text-success filter-button' onClick={handleSearch} className='ml-1' size="large" disabled={loading2}>
                                                                    {loading2 ? <Spin size="sm" /> : "Ara"}
                                                                   </Button>
                                                                   

                                                               </div>
                            </CardHeader>
                            <CardBody>
                                {loading ? <Table
                                    rowKey='categoryId'
                                    dataSource={filteredData.length > 0 ? filteredData : data}
                                    rowSelection={rowSelection}
                                    columns={columns}
                                    pagination={{ 
                                        showSizeChanger: true,
                                        pageSizeOptions: ['10', '20', '50', '100'],
                                        showTotal: (total, range) => `${range[0]}-${range[1]} / ${total}`,  
                                     }}
                                    components={{    
                                        body: {
                                            row: DraggableBodyRow,
                                        },
                                    }}
                                    onRow={(record, index) => ({
                                        index, // Dizideki gerçek indeks
                                        moveRow,
                                    })}
                                /> : <div className='w-100 mt-5 mb-5 d-flex justify-content-center align-items-center'>
                                    <Spinner style={{ width: '3rem', height: '3rem' }} color='info'> </Spinner>
                                </div>}
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </motion.div>
        </DndProvider>
    );
};

export default CategoryPage;
