export const mobilVariant = {
    initial: {
        opacity: 0,
        padding: 0,
        margin: 0,
        width: '100%',
        backgroundColor: '#F3f5f9'
    },
    animate: {
        opacity: 1,
        width: '100%',
        margin: 0,
        transition: { duration: .3 }
    },
    exit: {
        opacity: 0,
        transition: { duration: .3 }
    }
}