import React, { useEffect, useState } from "react";
import { Edit2, Home, PlusCircle, Trash2 } from "react-feather";
import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { motion } from "framer-motion";
import { Popconfirm, Table, Select, Button as Button2, Upload } from "antd";
import { toast } from "react-toastify";
import Header from "../../components/header";
import {
  createBlog,
  deleteBlog,
  getBlog,
  getAllImageGroups,
  updateBlog,
  getAllBlogs,
  updateBlogN,
  createBlogN,
} from "../../core/request";
import { mobilVariant } from "../../components/animation";
import { useNavigate } from "react-router-dom";
import { splitF } from "../../components/general";

const BlogPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [imageGroups, setImageGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    titleEn: "",
    desc: "",
    descEn: "",
    url: "",
    urlEn: "",
    content: "",
    contentEn: "",
    mediaID: null,
    imageGroupID: null,
    files: [],
  });

  //resim seçme işlmei için
  const uploadProps = {
    beforeUpload: (file) => {
      console.log("beforeUpload called with file:", file);
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        toast.error("Sadece resim yükleyebilirsiniz");
        return false;
      }
      return false;
    },
    onChange: (info) => {
      console.log("Upload onChange:", info.fileList);

      const validFiles = info.fileList
        .map((file) => {
          if (file.originFileObj) {
            return file.originFileObj;
          }
          return file;
        })
        .filter((file) => file); // undefined değerleri filtrele

      console.log("Valid files:", validFiles);

      setFormData((prev) => ({
        ...prev,
        files: validFiles,
      }));
    },
    multiple: true,
    // listType: "picture",
    defaultFileList: formData.files, //mevcut dosyaları göstermek için
  };

  const clearForm = () => {
    setFormData({
      title: "",
      titleEn: "",
      desc: "",
      descEn: "",
      url: "",
      urlEn: "",
      content: "",
      contentEn: "",
      mediaID: null,
      imageGroupID: null,
    });
    setSelectedData(null);
  };

  const getDatas = async () => {
    const imageGroups = await getAllImageGroups();
    const blogs = await getAllBlogs("");
    if (blogs.message === "Success") setData(blogs.result);

    if (imageGroups.message === "Success") {
      var newlist = [];
      for (var i = 0; i < imageGroups.result.length; i++) {
        if (imageGroups.result[i] !== undefined)
          newlist.push({
            value: imageGroups.result[i].imageGroupID,
            label: imageGroups.result[i].title,
          });
      }
      setImageGroups(newlist);
      setTimeout(() => {
        setLoading(true);
      }, 1000);
    }
  };

  const getData = async (id) => {
    const blog = await getBlog(id);
    setSelectedData(id);
    setFormData({
      title: blog.result.title,
      titleEn: blog.result.titleEn,
      desc: blog.result.shortDescription,
      descEn: blog.result.shortDescriptionEn,
      url: blog.result.url,
      urlEn: blog.result.urlEn,
      content: blog.result.content,
      contentEn: blog.result.contentEn,
      mediaID: blog.result.mediaID,
      imageGroupID: blog.result.imageGroupID,
      files: blog.result.files,
    });
  };

  const addData = async () => {
    let res;
    const files = formData.files || [];
    if (selectedData) {
      res = await updateBlogN(
        selectedData,
        formData.title,
        formData.titleEn,
        formData.desc,
        formData.descEn,
        formData.url,
        formData.urlEn,
        formData.content,
        formData.contentEn,
        formData.imageGroupID,
        formData.mediaID,
        files
      );
    } else {
      res = await createBlogN(
        formData.title,
        formData.titleEn,
        formData.desc,
        formData.descEn,
        formData.url,
        formData.urlEn,
        formData.content,
        formData.contentEn,
        formData.imageGroupID,
        formData.mediaID,
        files
      );
    }
    if (res.message === "Success") toast.success("Blog içeriği eklendi.");
    else toast.error("Blog içeriği eklenirken bir sorun oluştu!");
    getDatas();
    clearForm();
  };

  const deleteData = async (id) => {
    await deleteBlog(id);
    toast.success("Blog içeriği silindi.");
    clearForm();
    getDatas();
  };

  const columns = [
    {
      title: "ID",
      key: "blogId",
      width: 30,
      dataIndex: "blogId",
    },
    {
      title: "Başlık",
      key: "title",
      maxWidth: 30,
      render: (e) => splitF(e.title, 30),
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Açıklamalar",
      key: "description",
      maxWidth: 50,
      render: (e) => splitF(e.shortDescription, 50),
      sorter: (a, b) => a.shortDescription.length - b.shortDescription.length,
    },
    {
      title: "Dosyalar",
      key: "files",
      width: 70,
      render: (record) => (
        <div style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
          {record.files?.map((fileUrl, index) => (
            <p
              key={index}
              style={{
                padding: "4px 8px",
                background: "#D5EFEE",
                borderRadius: "4px",
                fontSize: "12px",
              }}
            >
              {fileUrl.split("/").pop()}
            </p>
          ))}
        </div>
      ),
    },
    {
      title: "İşlemler",
      width: 50,
      render: (e) => (
        <div className="d-flex justify-content-start">
          <div>
            <Button2
              className="border-warning text-warning"
              icon={<Edit2 size={20} />}
              onClick={() => getData(e.blogId)}
            />
          </div>
          <div className="ml-2">
            <Popconfirm
              title="Bu blog içeriğini silmek istediğinizden emin misiniz?"
              onConfirm={() => deleteData(e.blogId)}
              okText="Evet"
              cancelText="Hayır"
            >
              <Button2 icon={<Trash2 size={20} />} danger />
            </Popconfirm>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getDatas();
  }, []);

  return (
    <motion.div {...mobilVariant}>
      <Row className="w-100 pb-5">
        <Col sm={3} md={3} xl={3}>
          <Header />
        </Col>
        <Col sm={9} md={9} xl={9}>
          <div className="right-content mb-5">
            <div className="d-flex justify-content-between">
              <div>
                <h3>Blog Yönetimi</h3>
              </div>
              <div className="d-flex justify-content-end">
                <Button color="info" onClick={() => navigate("/image")}>
                  <PlusCircle
                    size={20}
                    style={{ marginTop: -3, marginRight: 7 }}
                  />
                  Resim Grubu Ekle
                </Button>
                <Button
                  onClick={() => navigate("/")}
                  className="ml-2 bg-white border border-info text-info"
                >
                  <Home size={20} style={{ marginTop: -5 }} /> Dashboard
                </Button>
              </div>
            </div>
            <Card className="shadow mt-3">
              <CardHeader
                className="pl-4 shadow-sm d-flex justify-content-between align-items-center bg-white p-3"
                tag="h4"
              >
                Blog Panosu Ekle
              </CardHeader>
              <CardBody>
                <Row className="p-1">
                  <Col md={6} className="mb-3">
                    <span>Başlık</span>
                    <Input
                      type="input"
                      value={formData.title}
                      onChange={(e) =>
                        setFormData({ ...formData, title: e.target.value })
                      }
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <span>Başlık (EN)</span>
                    <Input
                      type="input"
                      value={formData.titleEn}
                      onChange={(e) =>
                        setFormData({ ...formData, titleEn: e.target.value })
                      }
                    />
                  </Col>
                  {/* <Col md={4} className='mb-3'>
                                        <span>Resim Grubu</span>
                                        <Select
                                            options={imageGroups}
                                            className='w-100'
                                            style={{ height: 35 }}
                                            value={formData.imageGroupID}
                                            onChange={e => setFormData({ ...formData, imageGroupID: e })}
                                        />
                                    </Col> */}

                  <Col md={6} className="mb-3">
                    <span>Açıklama</span>
                    <Input
                      type="input"
                      value={formData.desc}
                      onChange={(e) =>
                        setFormData({ ...formData, desc: e.target.value })
                      }
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <span>Açıklama (EN)</span>
                    <Input
                      type="input"
                      value={formData.descEn}
                      onChange={(e) =>
                        setFormData({ ...formData, descEn: e.target.value })
                      }
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <span>İçerik</span>
                    <Input
                      type="input"
                      value={formData.content}
                      onChange={(e) =>
                        setFormData({ ...formData, content: e.target.value })
                      }
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <span>İçerik (EN)</span>
                    <Input
                      type="input"
                      value={formData.contentEn}
                      onChange={(e) =>
                        setFormData({ ...formData, contentEn: e.target.value })
                      }
                    />
                  </Col>
                  <Col md={6} className="mb-1 mt-3">
                    <span className="m-1">Resimler</span>
                    <Upload {...uploadProps} listType="picture">
                      <Button icon={<UploadOutlined />}>Resim Seç</Button>
                    </Upload>
                  </Col>
                  <Col md={6}>
                    {formData.files && formData.files.length > 0 && (
                      <div style={{ marginTop: "10px" }}>
                        <h6>Mevcut Dosyalar:</h6>
                        <div
                          style={{
                            padding: "10px",
                            border: "1px solid #e8e8e8",
                            borderRadius: "4px",
                          }}
                        >
                          {formData.files.map((file, index) => (
                            <div
                              key={index}
                              style={{
                                padding: "5px",
                                margin: "5px 0",
                                background: "#f5f5f5",
                                borderRadius: "4px",
                              }}
                            >
                              {typeof file === "string"
                                ? file.split("/").pop()
                                : file.name}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col md={12} className="mt-3 d-flex justify-content-end">
                    {selectedData ? (
                      <Button
                        onClick={() => clearForm()}
                        className="bg-white border-warning text-warning mr-1"
                      >
                        Temizle
                      </Button>
                    ) : null}
                    <Button
                      color={selectedData ? "warning" : "success"}
                      onClick={() => addData()}
                    >
                      <PlusCircle
                        size={20}
                        style={{ marginTop: -3, marginRight: 7 }}
                      />
                      {selectedData ? "Güncelle" : "Ekle"}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="shadow mt-4">
              <CardHeader className="pl-4 d-flex shadow-sm justify-content-between align-items-center bg-white p-3">
                <div>
                  <h4>Blog Listesi</h4>
                </div>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <Table rowKey="blogId" dataSource={data} columns={columns} />
                ) : (
                  <div className="w-100 mt-5 mb-5 d-flex justify-content-center align-items-center">
                    <Spinner
                      style={{ width: "3rem", height: "3rem" }}
                      color="info"
                    >
                      {" "}
                    </Spinner>
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    </motion.div>
  );
};

export default BlogPage;
