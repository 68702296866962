import React, { useEffect, useState } from 'react';
import { Home, List, PlusCircle } from 'react-feather';
import { Button, Card, CardBody, CardHeader, Col, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import Header from '../../components/header';
import { createImage, deleteImage, getAllImagesById, getImage, updateImage } from '../../core/request';
import { mobilVariant } from '../../components/animation';
import { useNavigate, useParams } from 'react-router-dom';
import { Popconfirm } from 'antd';
import { url2 } from '../../api';

const ImagePage = () => {
    const { id } = useParams()
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState(null)
    const [files, setFiles] = useState([])
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [formData, setFormData] = useState({
        file: null,
        filePath: "",
        field1: "",
        field1EN: "",
        field2: "",
        field2EN: "",
        field3: "",
        field3EN: "",
    })

    const clearForm = () => {
        setFormData({ file: null, field1: "", field1EN: "", field2: "", field2EN: "", field3: "", field3EN: "", })
        setSelectedData(null)
    }

    const getDatas = async () => {
        const images = await getAllImagesById(id);
        if (images.message === "Success")
            setData(images.result);

        setTimeout(() => {
            setLoading(true)
        }, 1000);
    }

    const getData = async (id) => {
        const data = await getImage(id);
        setSelectedData(id);
        if (data.result) {
            console.log(data)
            setFormData({ filePath: `${url2}${data.result.fileFullPath}`, field1: data.result.additionalField1, field1EN: data.result.additionalField1EN, field2: data.result.additionalField2, field2EN: data.result.additionalField2EN, field3: data.result.additionalField3, field3EN: data.result.additionalField3EN })
        }
    }

    const addData = async () => {
        if (isSubmitting) return; 
        setIsSubmitting(true); 
        if (files.length > 1) {
            for (var i = 0; i < files.length; i++) {
                console.log(files[i])
                const image = await createImage(files[i], "", "", "", "", "", "", id);
                if (image.message === "Success")
                    toast.success("Resim eklendi.")
                else
                    toast.error("Resim eklenirken bir sorun oluştu!")
            }
        } else if (files.length === 1) {
            let res;
            if (selectedData) {
                res = await updateImage(selectedData, formData.file, formData.field1, formData.field1EN, formData.field2, formData.field2EN, formData.field3, formData.field3EN, id)
            } else {
                res = await createImage(formData.file, formData.field1, formData.field1EN, formData.field2, formData.field2EN, formData.field3, formData.field3EN, id)
            }
            if (res.message === "Success")
                toast.success("Resim eklendi.")
            else
                toast.error("Resim eklenirken bir sorun oluştu!")
        } else {
            var res = await updateImage(selectedData, formData.file, formData.field1, formData.field1EN, formData.field2, formData.field2EN, formData.field3, formData.field3EN, id)
            if (res.message === "Success")
                toast.success("Resim eklendi.")
            else
                toast.error("Resim eklenirken bir sorun oluştu!")
        }
        clearForm()
        getDatas()
        setModal(false)
    }

    const deleteData = async (id) => {
        const image = await deleteImage(id);
        if (image.message === "Success")
            toast.success("Resim grubu içeriği silindi.")
        else
            toast.error("Resim grubu içeriği silinirken bir sorun oluştu!")
        getDatas()
    }

    useEffect(() => {
        getDatas();
    }, []);

    return (
        <motion.div {...mobilVariant}>
            <Row className='w-100 pb-5'>
                <Col sm={3} md={3} xl={3}>
                    <Header />
                </Col>
                <Col sm={9} md={9} xl={9}>
                    <div className='right-content mb-5'>
                        <div className='d-flex justify-content-between'>
                            <div><h3>{data[0] === undefined ? "" : data[0].imageGroup.title}</h3></div>
                            <div className='d-flex justify-content-end'>
                                <Button color='info' onClick={() => navigate("/image")}><List size={20} style={{ marginTop: -3, marginRight: 7 }} />Resim Grubu Listesi</Button>
                                <Button onClick={() => navigate("/")} className='ml-2 bg-white border border-info text-info'><Home size={20} style={{ marginTop: -5 }} /> Dashboard</Button>
                            </div>
                        </div>
                        <Card className='shadow mt-4'>
                            <CardHeader className='pl-4 d-flex shadow-sm justify-content-between align-items-center bg-white p-3'>
                                <div><h4>Resim Grubu Listesi</h4></div>
                                <div><Button className='bg-white border-success text-success' onClick={() => { clearForm(); setModal(!modal) }}><PlusCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />Ekle</Button></div>
                            </CardHeader>
                            <CardBody>
                                <Row className='fileCards'>
                                    {
                                        data.map((item, index) => {
                                            return (
                                                loading ? <Col key={index} md={6} className='mb-3'>
                                                    <Card className='position-relative'>
                                                        <div style={{ position: 'absolute', top: -15, left: -15, width: 36, height: 36, borderRadius: 18, backgroundColor: '#17a2b8', color: 'white', fontSize: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            {item.imageID}
                                                        </div>
                                                        <CardBody>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <Row>
                                                                        <Col md={6}>
                                                                            <Popconfirm
                                                                                title="Bu resmi silmek istediğinizden emin misiniz?"
                                                                                onConfirm={() => deleteData(item.imageID)}
                                                                                okText="Evet"
                                                                                cancelText="Hayır"
                                                                            >
                                                                                <div style={{ cursor: 'pointer' }} className='border rounded mb-3 text-center border-danger text-danger'>Sil</div>
                                                                            </Popconfirm>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <div style={{ cursor: 'pointer' }} onClick={() => { getData(item.imageID); setModal(true); }} className='border rounded mb-3 text-center border-warning text-warning'>Güncelle</div>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col md={4} className='d-flex align-items-center'>
                                                                    <img src={`${url2}/${item.fileFullPath}`} className='w-100' />
                                                                </Col>
                                                                <Col md={8}>
                                                                    <div className='mb-3'>
                                                                        <div><h6 className='text-secondary'>Ek Alan 1:</h6></div>
                                                                        <div style={{ marginTop: -7 }}>{item.additionalField1}</div>
                                                                    </div>
                                                                    <div className='mb-3'>
                                                                        <div><h6 className='text-secondary'>Ek Alan 2:</h6></div>
                                                                        <div style={{ marginTop: -7 }}>{item.additionalField2}</div>
                                                                    </div>
                                                                    <div className='mb-3'>
                                                                        <div><h6 className='text-secondary'>Ek Alan 3:</h6></div>
                                                                        <div style={{ marginTop: -7 }}>{item.additionalField3}</div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col> : <div className='w-100 mt-5 mb-5 d-flex justify-content-center align-items-center'>
                                                    <Spinner style={{ width: '3rem', height: '3rem' }} color='info'> </Spinner>
                                                </div>
                                            )
                                        })
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>

            <Modal isOpen={modal} toggle={() => setModal(!modal)} className='modal-dialog-centered'>
                <ModalHeader toggle={() => setModal(!modal)}>
                    <div><h4>{selectedData ? "Medya Güncelle" : "Medya Ekle"}</h4></div>
                    {/* <div className='mt-2'><small className='text-secondary'>Çoklu resim ekleme işlemlerinde ek alanları düzenleme ile girmeniz gerekir.</small></div> */}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={3} className='mb-3 d-flex align-items-center'>
                            <img src={formData.file ? URL.createObjectURL(formData.file) : formData.filePath} alt="" className='w-100' />
                        </Col>
                        <Col md={9} className='mb-3 d-flex align-items-center'>
                            <div>
                                <span>Resim</span>
                                <Input
                                    type='file'
                                    multiple
                                    onChange={e => {
                                        setFiles(e.target.files)
                                        setFormData(a => ({ ...a, file: e.target.files[0] }))
                                    }}
                                />
                            </div>
                        </Col>
                        {/* <Col md={12} className='mb-3'>
                            <span>Ek Alan 1</span>
                            <Input
                                type='input'
                                disabled={files.length > 1}
                                value={formData.field1}
                                onChange={e => setFormData(a => ({ ...a, field1: e.target.value }))}
                            />
                        </Col>
                        <Col md={12} className='mb-3'>
                            <span>Ek Alan 1 (EN)</span>
                            <Input
                                type='input'
                                disabled={files.length > 1}
                                value={formData.field1EN}
                                onChange={e => setFormData(a => ({ ...a, field1EN: e.target.value }))}
                            />
                        </Col>
                        <Col md={12} className='mb-3'>
                            <span>Ek Alan 2</span>
                            <Input
                                type='input'
                                disabled={files.length > 1}
                                value={formData.field2}
                                onChange={e => setFormData(a => ({ ...a, field2: e.target.value }))}
                            />
                        </Col>
                        <Col md={12} className='mb-3'>
                            <span>Ek Alan 2 (EN)</span>
                            <Input
                                type='input'
                                disabled={files.length > 1}
                                value={formData.field2EN}
                                onChange={e => setFormData(a => ({ ...a, field2EN: e.target.value }))}
                            />
                        </Col>
                        <Col md={12} className='mb-3'>
                            <span>Ek Alan 3</span>
                            <Input
                                type='input'
                                disabled={files.length > 1}
                                value={formData.field3}
                                onChange={e => setFormData(a => ({ ...a, field3: e.target.value }))}
                            />
                        </Col>
                        <Col md={12} className='mb-3'>
                            <span>Ek Alan 3(EN)</span>
                            <Input
                                type='input'
                                disabled={files.length > 1}
                                value={formData.field3EN}
                                onChange={e => setFormData(a => ({ ...a, field3EN: e.target.value }))}
                            />
                        </Col> */}
                    </Row>
                    <Button color={selectedData ? 'warning' : 'success'} className='mt-4 w-100' onClick={() => addData()} disabled={isSubmitting}>
                       
        {selectedData ? "Güncelle" : "Ekle"}</Button>
                </ModalBody>
            </Modal>
        </motion.div>
    );
};

export default ImagePage;
