import React, { useEffect, useState } from 'react';
import { Edit2, Home, PlusCircle, Trash2 } from 'react-feather';
import { Button, Card, CardBody, CardHeader, Col, Input, Row, Spinner } from 'reactstrap';
import { motion } from 'framer-motion';
import { Popconfirm, Table, Select, Button as Button2, Tooltip } from 'antd';
import { toast } from 'react-toastify';
import Header from '../../components/header';
import { register, deleteUser, getUser, getAllUsers, updateUser } from '../../core/request';
import { mobilVariant } from '../../components/animation';
import { Roles } from '../../types';

const UserPage = () => {
    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState(null)
    const [selectRole, setSelectRole] = useState()
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({ firstName: "", lastName: "", userName: "", email: "", phoneNumber: "", password: "", roles: [] });

    const clearForm = () => {
        setFormData({ firstName: "", lastName: "", email: "", phoneNumber: "", password: "", userName: "", roles: [] })
        setSelectedData(null)
    }

    const getDatas = async () => {
        const users = await getAllUsers("");
        if (users.message === "Success")
            setData(users.result);

        setTimeout(() => {
            setLoading(true)
        }, 1000);
    };

    const getData = async (id) => {
        const user = await getUser(id);
        setSelectedData(id)
        const role = user.result.roles[0].name
        for (var i = 0; i < Roles.length; i++)
            if (Roles[i].label === role) {
                console.log(Roles[i])
                setSelectRole(Roles[i])
            }
        setFormData({ firstName: user.result.firstName, lastName: user.result.lastName, email: user.result.email, phoneNumber: user.result.phoneNumber, userName: user.result.userName, roles: [] })
    }

    const addData = async () => {
        console.log(formData)
        const user = await register(formData.firstName, formData.lastName, formData.userName, formData.email, formData.phoneNumber, formData.password, formData.roles);
        if (user.message === "Success")
            toast.success("Kullanıcı içeriği eklendi.")
        else
            toast.error("Kullanıcı içeriği eklenirken bir sorun oluştu!")
        clearForm()
        getDatas()
    }

    const deleteData = async (id) => {
        const user = await deleteUser(id);
        if (user.message === "Success")
            toast.success("Kullanıcı içeriği silindi.")
        else
            toast.error("Kullanıcı içeriği silinirken bir sorun oluştu!")
        clearForm()
        getDatas()
    }

    const editData = async () => {
        const user = await updateUser(selectedData, formData.firstName, formData.lastName, formData.userName, formData.email, formData.phoneNumber);
        if (user.message === "Success")
            toast.success("Kullanıcı içeriği güncellendi.")
        else
            toast.error("Kullanıcı içeriği güncellenirken bir sorun oluştu!")
        getDatas()
        clearForm()
    }

    const columns = [
        {
            title: "İsim",
            key: "firstname",
            render: (e) => `${e.firstName} ${e.lastName}`
        },
        {
            title: "Mail",
            key: "email",
            dataIndex: "email"
        },
        {
            title: "Telefon",
            key: "phoneNumber",
            dataIndex: "phoneNumber"
        },
        {
            title: "Rol",
            key: "roles",
            render: (e) => e.roles[0] === undefined ? "" : e.roles[0].name
        },
        {
            title: "İşlemler",
            render: (e) => (
                <div className='d-flex justify-content-start'>
                    <div className='ml-2'>
                        <Tooltip title="Düzenle">
                            <Button2 className='border-warning text-warning' icon={<Edit2 size={20} />} onClick={() => getData(e.userId)} />
                        </Tooltip>
                    </div>
                    <div className='ml-2'>
                        <Tooltip title="Sil">
                            <Popconfirm
                                title="Bu kullanıcıyı silmek istediğinizden emin misiniz?"
                                onConfirm={() => deleteData(e.userId)}
                                okText="Evet"
                                cancelText="Hayır"
                            >
                                <Button2 icon={<Trash2 size={20} />} danger />
                            </Popconfirm>
                        </Tooltip>
                    </div>
                </div>
            )
        }
    ];

    useEffect(() => {
        getDatas();
    }, []);

    return (
        <motion.div {...mobilVariant}>
            <Row className='w-100 pb-5'>
                <Col sm={3} md={3} xl={3}>
                    <Header />
                </Col>
                <Col sm={9} md={9} xl={9}>
                    <div className='right-content mb-5'>
                        <div className='d-flex justify-content-between'>
                            <div><h3>Kullanıcı Yönetimi</h3></div>
                            <div className='d-flex justify-content-end'>
                                <Button className='ml-2 bg-white border border-info text-info'><Home size={20} style={{ marginTop: -5 }} /> Dashboard</Button>
                            </div>
                        </div>
                        <Card className='shadow mt-3'>
                            <CardHeader className='pl-4 shadow-sm d-flex justify-content-between align-items-center bg-white p-3' tag='h4'>Kullanıcı Ekle</CardHeader>
                            <CardBody>
                                <Row className='p-1'>
                                    <Col md={3} className='mb-3'>
                                        <span>İsmi</span>
                                        <Input type='input' value={formData.firstName} onChange={e => setFormData({ ...formData, firstName: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Soyismi</span>
                                        <Input type='input' value={formData.lastName} onChange={e => setFormData({ ...formData, lastName: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>Kullanıcı Adı</span>
                                        <Input type='input' value={formData.userName} onChange={e => setFormData({ ...formData, userName: e.target.value })} />
                                    </Col>
                                    <Col md={3} className='mb-3'>
                                        <span>E-Mail</span>
                                        <Input type='input' value={formData.email} onChange={e => setFormData({ ...formData, email: e.target.value })} />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Telefon</span>
                                        <Input type='input' value={formData.phoneNumber} onChange={e => setFormData({ ...formData, phoneNumber: e.target.value })} />
                                    </Col>
                                    {selectedData ? null : <Col md={4} className='mb-3'>
                                        <span>Rol</span>
                                        <Select
                                            options={Roles}
                                            className='w-100'
                                            style={{ height: 35 }}
                                            value={selectRole}
                                            onChange={e => {
                                                var role = []
                                                for (var i = 0; i < Roles.length; i++) {
                                                    if (Roles[i].value === e) {
                                                        role.push(Roles[i].label)
                                                    }
                                                }
                                                setFormData({ ...formData, roles: role })
                                            }}
                                        />
                                    </Col>}
                                    {selectedData ? null : <Col md={4} className='mb-3'>
                                        <span>Şifre</span>
                                        <Input type='input' value={formData.paspassword} onChange={e => setFormData({ ...formData, password: e.target.value })} />
                                    </Col>}
                                    <Col md={12} className='mt-3 d-flex justify-content-end'>
                                        {selectedData ? <Button color='light' className='border-dark mr-2' onClick={() => clearForm()}>Temizle</Button> : null}
                                        <Button
                                            color={selectedData ? 'warning' : 'success'}
                                            onClick={() => selectedData ? editData() : addData()}
                                        >
                                            <PlusCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />{selectedData ? "Güncelle" : "Ekle"}
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='shadow mt-4'>
                            <CardHeader className='pl-4 d-flex shadow-sm justify-content-between align-items-center bg-white p-3'>
                                <div><h4>Kullanıcı Listesi</h4></div>
                            </CardHeader>
                            <CardBody>
                                {loading ? <Table
                                    rowKey='phoneNumber'
                                    dataSource={data}
                                    columns={columns}
                                /> : <div className='w-100 mt-5 mb-5 d-flex justify-content-center align-items-center'>
                                    <Spinner style={{ width: '3rem', height: '3rem' }} color='info'> </Spinner>
                                </div>}
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </motion.div>
    );
};

export default UserPage;
