import React, { useEffect, useState } from 'react'
import '../../css/style.css'
import { Button, Card, Form, Input } from 'reactstrap'
import { Eye, EyeOff } from 'react-feather'
import { motion } from 'framer-motion'
import { mobilVariant } from '../../components/animation'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { AUTH_LOGIN } from '../../api'

const LoginPage = () => {
    const [viewPass, setViewPass] = useState(false)
    const [load, setLoad] = useState(false)
    const { handleSubmit } = useForm()

    const onSubmit = (values, e) => {
        axios.post(AUTH_LOGIN, {
            "userName": e.target[0].value,
            "password": e.target[1].value
        }).then(res => {
            if (res.status === 200) {
                localStorage.setItem("auth", JSON.stringify(res.data))
                toast.success(`Hoşgeldin ${res.data.name}.`)
                setTimeout(() => {
                    window.location.href = '/'
                }, 1000)
            } else if (res.status === 401) {
                toast.error("Kullanıcı bilgileriniz yanlış!")
            } else {
                toast.error(res.data.title)
            }
        }).catch(er => toast.error(er))
    }

    useEffect(() => {
        setTimeout(() => {
            setLoad(true)
        }, 300)
    }, [])

    return (
        load ? <motion.div {...mobilVariant} className='login'>
            <Card className='shadow'>
                <div className='text-center'><h1>Admin Panel</h1></div>
                <div className='mt-3'>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <span>Kullanıcı Adınız</span>
                            <Input type='input' placeholder='****@****.com' />
                        </div>
                        <div className='mt-3'>
                            <span>Şifreniz</span>
                            <div className='password'>
                                <div className='pass'>
                                    <Input type={!viewPass ? 'password' : 'input'} placeholder='*******' className='float-left' />
                                </div>
                                <div className='symbol'>
                                    {viewPass ? <EyeOff onClick={() => setViewPass(!viewPass)} /> : <Eye onClick={() => setViewPass(!viewPass)} />}
                                </div>
                            </div>
                        </div>
                        <div className='mt-4'>
                            <Button className='w-100'>Giriş Yap</Button>
                        </div>
                    </Form>
                </div>
            </Card>
        </motion.div> : <div className='loader'>
            <h1>Admin Panel</h1>
        </div>
    )
}

export default LoginPage