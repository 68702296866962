import React, { useEffect, useRef, useState } from "react";
import { Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Edit2, Home, PlusCircle, Trash2 } from "react-feather";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { motion } from "framer-motion";
import { Popconfirm, Table, Select, Button as Button2, Spin } from "antd";
import { toast } from "react-toastify";
import Header from "../../components/header";
import {
  ateBrancred,
  deleteBrand,
  getBrand,
  updateBrand,
  getAllBrands,
  getAllImageGroupsAll,
  getBrandParams,
  sortBrand,
  updateBrandN,
  createBrandN,
} from "../../core/request";
import { mobilVariant } from "../../components/animation";
import { useNavigate } from "react-router-dom";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

//sürükle bırak işlemleri için
const DraggableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = useRef();

  // Satırı sürüklenebilir hale getirme
  const [{ isDragging }, drag] = useDrag({
    type: "draggableRow",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  // Hedef satır bırakıldığında moveRow'u çalıştırma
  const [, drop] = useDrop({
    accept: "draggableRow",
    drop: (item) => {
      if (item.index !== index) {
        moveRow(item.index, index); // Bırakma anında çalışır
        item.index = index;
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });
  // Satırı hem sürüklenebilir hem bırakılabilir hale getirmek için
  drag(drop(ref));
  return (
    <tr
      ref={ref}
      className={`draggable-row ${className}`}
      style={{ ...style, opacity: isDragging ? 0.5 : 1 }}
      {...restProps}
    />
  );
};

const BrandPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [multipleLoad, setMultipleLoad] = useState(false);
  const [imageGroups, setImageGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    brandName: "",
    brandNameEN: "",
    shortDescription: "",
    shortDescriptionEN: "",
    content: "",
    contentEN: "",
    imageGroupID: null,
    files: [],
  });

  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loading2, setLoading2] = useState(false);

  //resim seçme işlmei için
  const uploadProps = {
    beforeUpload: (file) => {
      console.log("beforeUpload called with file:", file);
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        toast.error("Sadece resim yükleyebilirsiniz");
        return false;
      }
      return false; 
    },
    onChange: (info) => {
      console.log("Upload onChange:", info.fileList);

      const validFiles = info.fileList
        .map((file) => {
          if (file.originFileObj) {
            return file.originFileObj;
          }
          return file;
        })
        .filter((file) => file); // undefined değerleri filtrele

      console.log("Valid files:", validFiles);

      setFormData((prev) => ({
        ...prev,
        files: validFiles,
      }));
    },
    multiple: true,
    // listType: "picture",
    defaultFileList: formData.files//mevcut dosyaları göstermek için
  };

  console.log("Selected files:", formData.files);

  //search işlemi için

  const handleSearch = async () => {
    setLoading2(true);
    try {
      console.log("Search Text:", searchText);
      const result = await getBrandParams(searchText);
      console.log("API Result:", result);
      if (result?.message === "Success") {
        const searchResult = result.result ? [result.result] : [];
        setFilteredData(searchResult);
        console.log("Filtered Data:", result.result);
      } else {
        toast.error("Marka adını lütfen doğru giriniz!");
      }
    } catch (error) {
      toast.error("Ürünler getirilirken bir hata oluştu: " + error.message);
    }
    setSearchText("");
    setLoading2(false);
  };

  // filtreleme sonrası reset yapmak için
  const resetBrand = async () => {
    try {
      setLoading2(true);
      await getDatas();
      setFilteredData([]); // Clear filtered data
      setSearchText(""); // Clear search text
      toast.success("Tüm markalar listelendi");
    } catch (error) {
      console.error("Reset error:", error);
      toast.error("Markalar getirilirken bir hata oluştu: " + error.message);
    } finally {
      setLoading2(false);
    }
  };

  const clearForm = () => {
    setFormData({
      brandName: "",
      brandNameEN: "",
      shortDescription: "",
      shortDescriptionEN: "",
      content: "",
      contentEN: "",
      imageGroupID: null,
      files: [],
    });
    setSelectedData(null);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // const getDatas = async () => {
  //     setLoading(true);
  //     try {
  //         const imageGroupsResponse = await getAllImageGroupsAll();
  //         const brandsResponse = await getAllBrands();
  //         if (brandsResponse.message === "Success") {
  //             // Backend'den gelen sıralama bilgisini kullan
  //             let brands = brandsResponse.result;
  //             brands.sort((a, b) => (a.sort || 0) - (b.sort || 0));
  //             setData(brands);

  //             }

  //         if (imageGroupsResponse.message === "Success") {
  //             const newList = imageGroupsResponse.result
  //                 .filter(group => group !== undefined)
  //                 .map(group => ({ value: group.imageGroupID, label: group.title }));
  //             setImageGroups(newList);
  //         }
  //     } catch (error) {
  //         toast.error("Veriler alınırken bir hata oluştu: " + error.message);
  //     }
  //     setLoading(false);
  // };

  const getDatas = async () => {
    setLoading(true);
    try {
      const imageGroupsResponse = await getAllImageGroupsAll();
      const brandsResponse = await getAllBrands();

      if (brandsResponse.message === "Success") {
        let brands = brandsResponse.result;

        console.log(
          "Brands before sort:",
          brands.map((b) => ({ id: b.brandId, sort: b.sort }))
        );

        brands.sort((a, b) => {
          if (a.sort === null && b.sort === null) return 0;
          if (a.sort === null) return 1;
          if (b.sort === null) return -1;
          return a.sort - b.sort;
        });

        console.log(
          "Brands after sort:",
          brands.map((b) => ({ id: b.brandId, sort: b.sort }))
        );

        setData(brands);
      }

      if (imageGroupsResponse.message === "Success") {
        const newList = imageGroupsResponse.result
          .filter((group) => group !== undefined)
          .map((group) => ({ value: group.imageGroupID, label: group.title }));
        setImageGroups(newList);
      }
    } catch (error) {
      console.error("GetDatas Error:", error);
      toast.error("Veriler alınırken bir hata oluştu: " + error.message);
    }
    setLoading(false);
  };

  const getData = async (id) => {
    try {
      const brand = await getBrand(id);
      if (brand?.result) {
        setSelectedData(id);
        setFormData({
          brandName: brand.result.brandName || "",
          brandNameEN: brand.result.brandNameEN || "",
          shortDescription: brand.result.shortDescription || "",
          shortDescriptionEN: brand.result.shortDescriptionEN || "",
          content: brand.result.content || "",
          contentEN: brand.result.contentEN || "",
          imageGroupID: brand.result.imageGroupID || null,
          files: brand.result.files || [],
        });
      }
    } catch (error) {
      console.error("GetData Error:", error);
      toast.error("Marka bilgileri alınamadı!");
    }
  };

  const multipleDelete = async () => {
    setMultipleLoad(true);
    if (selectedRowKeys.length > 0) {
      for (var i = 0; i < selectedRowKeys.length; i++) {
        await deleteBrand(selectedRowKeys[i]);
      }
      await getDatas();
    }
    setMultipleLoad(false);
  };

  const addData = async () => {
    let res;
    const files = formData.files || [];

    if (selectedData) {
      console.log("Updating brand with ID:", selectedData);
      res = await updateBrandN(
        selectedData,
        formData.brandName,
        formData.brandNameEN,
        formData.shortDescription,
        formData.shortDescriptionEN,
        formData.content,
        formData.contentEN,
        formData.imageGroupID || null,
        files
      );
      console.log("Update Result:", res);
    } else {
      res = await createBrandN(
        formData.brandName,
        formData.brandNameEN,
        formData.shortDescription,
        formData.shortDescriptionEN,
        formData.content,
        formData.contentEN,
        formData.imageGroupID || null,
        files
      );
    }
    if (res.message === "Success") {
      toast.success("Marka içeriği eklendi.");
      await getDatas();
      clearForm();
    } else {
      toast.error(
        `Marka ${
          selectedData ? "güncellenirken" : "eklenirken"
        } bir sorun oluştu!`
      );
      getDatas();
      clearForm();
    }
  };

  const deleteData = async (id) => {
    const brand = await deleteBrand(id);
    if (brand.message === "Success") toast.success("Marka içeriği silindi.");
    else toast.error("Marka içeriği silinirken bir sorun oluştu!");
    clearForm();
    getDatas();
  };

  const [columns, setColumns] = useState([
    {
      title: "ID",
      key: "brandId",
      width: 30,
      dataIndex: "brandId",
    },
    {
      title: "Başlık",
      key: "title",
      minWidth: 50,
      maxWidth: 50,
      render: (e) => e.brandName,
      sorter: (a, b) => a.brandName.localeCompare(b.brandName), // localeCompare ile sıralama yapılıyor iki stringi dil duyarlılığına göre sıralıyor.
    },
    {
      title: "Açıklamalar",
      key: "shortDescription",
      minWidth: 50,
      maxWidth: 50,
      render: (e) => e.shortDescription,
      sorter: (a, b) => a.shortDescription.localeCompare(b.shortDescription),
    },
    {
        title: "Dosyalar",
        key: "files",
        width: 100,
        // maxWidth: 30,
        render: (record) => (
            <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
                {record.files?.map((fileUrl, index) => (
                   <p
                   key={index}
                   style={{
                       padding: '4px 8px',
                       background: '#D5EFEE',
                       borderRadius: '4px',                           
                       fontSize: '12px'
                   }}
               >
                   {fileUrl.split('/').pop()}
               </p>
                ))}
            </div>
        )
    },
    {
      title: "İşlemler",
      width: 50,
      render: (e) => (
        <div className="d-flex justify-content-start">
          <div>
            <Button2
              className="border-warning text-warning"
              icon={<Edit2 size={20} />}
              onClick={() => getData(e.brandId)}
            />
          </div>
          <div className="ml-2">
            <Popconfirm
              title="Bu marka içeriğini silmek istediğinizden emin misiniz?"
              onConfirm={() => deleteData(e.brandId)}
              okText="Evet"
              cancelText="Hayır"
            >
              <Button2 icon={<Trash2 size={20} />} danger />
            </Popconfirm>
          </div>
        </div>
      ),
    },
  ]);

  const moveRow = async (dragIndex, hoverIndex) => {
    const draggedRow = data[dragIndex];
    const updateData = [...data]; //data arrayini updateData arrayine kopyaladım
    updateData.splice(dragIndex, 1); //draggedRow'u sildim
    updateData.splice(hoverIndex, 0, draggedRow); //hoverIndex'e draggedRow'u ekledim
    // setData(updateData); //data arrayini updateData arrayine eşitledim

    try {
      const result = await sortBrand(draggedRow.brandId, hoverIndex);

      if (result?.message === "Success") {
        await getDatas();
        toast.success("Sıralama güncellendi");
      } else {
        toast.error("Sıralama güncellenemedi!");
        await getDatas();
      }
    } catch (error) {
      console.error("Sort error:", error);
      toast.error(`Sıralama hatası: ${error.message}`);
      getDatas();
    }
  };

  useEffect(() => {
    getDatas();
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <motion.div {...mobilVariant}>
        <Row className="w-100 pb-5">
          <Col sm={3} md={3} xl={3}>
            <Header />
          </Col>
          <Col sm={9} md={9} xl={9}>
            <div className="right-content mb-5">
              <div className="d-flex justify-content-between">
                <div>
                  <h3>Marka Yönetimi</h3>
                </div>
                <div className="d-flex justify-content-end">
                  <Button color="info" onClick={() => navigate("/image")}>
                    <PlusCircle
                      size={20}
                      style={{ marginTop: -3, marginRight: 7 }}
                    />
                    Resim Grubu Ekle
                  </Button>
                  <Button
                    onClick={() => navigate("/")}
                    className="ml-2 bg-white border border-info text-info"
                  >
                    <Home size={20} style={{ marginTop: -5 }} /> Dashboard
                  </Button>
                </div>
              </div>
              <Card className="shadow mt-3">
                <CardHeader
                  className="pl-4 shadow-sm d-flex justify-content-between align-items-center bg-white p-3"
                  tag="h4"
                >
                  Marka {selectedData ? "Güncelle" : "Ekle"}
                </CardHeader>
                <CardBody>
                  <Row className="p-1">
                    <Col md={6} className="mb-3">
                      <span>Marka Adı</span>
                      <Input
                        type="input"
                        value={formData.brandName}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            brandName: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col md={6} className="mb-3">
                      <span>Marka Adı (EN)</span>
                      <Input
                        type="input"
                        value={formData.brandNameEN}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            brandNameEN: e.target.value,
                          })
                        }
                      />
                    </Col>
                    {/* <Col md={4} className='mb-3'>
                                        <span>Resim Grubu</span>
                                        <Select
                                            options={imageGroups}
                                            className='w-100'
                                            style={{ height: 35 }}
                                            value={formData.imageGroupID}
                                            onChange={e => setFormData({ ...formData, imageGroupID: e })}
                                        />
                                    </Col> */}
                    
                    <Col md={6} className="mb-3">
                      <span>Açıklama</span>
                      <Input
                        type="input"
                        value={formData.shortDescription}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            shortDescription: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col md={6} className="mb-3">
                      <span>Açıklama (EN)</span>
                      <Input
                        type="input"
                        value={formData.shortDescriptionEN}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            shortDescriptionEN: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col md={6} className="mb-3">
                      <span>İçerik</span>
                      <Input
                        type="input"
                        value={formData.content}
                        onChange={(e) =>
                          setFormData({ ...formData, content: e.target.value })
                        }
                      />
                    </Col>
                    <Col md={6} className="mb-3">
                      <span>İçerik (EN)</span>
                      <Input
                        type="input"
                        value={formData.contentEN}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            contentEN: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col md={6} className="mb-1 mt-3">
                      <span className="m-1">Resimler</span>
                      <Upload {...uploadProps} listType="picture">
                        <Button icon={<UploadOutlined />}>Resim Seç</Button>
                      </Upload>
                    </Col>
                     <Col md={6}>
                        {formData.files && formData.files.length > 0 && (
                            <div style={{ marginTop: '10px' }}>
                                <h6>Mevcut Dosyalar:</h6>
                                <div style={{ 
                                    padding: '10px',
                                    border: '1px solid #e8e8e8',
                                    borderRadius: '4px'
                                }}>
                                    {formData.files.map((file, index) => (
                                        <div 
                                            key={index} 
                                            style={{
                                                padding: '5px',
                                                margin: '5px 0',
                                                background: '#f5f5f5',
                                                borderRadius: '4px'
                                            }}
                                        >
                                            {typeof file === 'string' 
                                                ? file.split('/').pop() 
                                                : file.name
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </Col>

                    <Col md={12} className="mt-3 d-flex justify-content-end">
                      {selectedData ? (
                        <Button
                          onClick={() => clearForm()}
                          className="bg-white border-warning text-warning mr-1"
                        >
                          Temizle
                        </Button>
                      ) : null}
                      <Button
                        color={selectedData ? "warning" : "success"}
                        onClick={() => addData()}
                      >
                        <PlusCircle
                          size={20}
                          style={{ marginTop: -3, marginRight: 7 }}
                        />
                        {selectedData ? "Güncelle" : "Ekle"}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="shadow mt-4">
                <CardHeader className="pl-4 d-flex shadow-sm justify-content-between align-items-center bg-white p-3">
                  <div>
                    <h4>Marka Listesi</h4>
                  </div>
                  {selectedRowKeys.length > 0 ? (
                    <div>
                      <Button
                        onClick={multipleDelete}
                        className="d-flex justify-content-center text-white"
                        color="danger"
                      >
                        <Trash2
                          size={20}
                          style={{ marginTop: 3, marginRight: 7 }}
                        />{" "}
                        {multipleLoad ? (
                          <Spinner
                            style={{ width: 23, height: 23 }}
                            color="white"
                          >
                            {" "}
                          </Spinner>
                        ) : (
                          "Toplu Sil"
                        )}
                      </Button>
                    </div>
                  ) : null}{" "}
                  <Button
                    color="border border-info bg-transparent text-info filter-button"
                    onClick={resetBrand}
                    className="ml-1"
                    size="large"
                    disabled={loading2}
                  >
                    {loading2 ? <Spin size="sm" /> : "Tümü"}
                  </Button>
                  <div className="d-flex align-items-center">
                    <Input
                      className="filter-search border border-success "
                      size="large"
                      placeholder="Marka Ara"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                    <Button
                      color="border border-success bg-transparent text-success filter-button"
                      onClick={() => handleSearch(1)}
                      className="ml-1"
                      size="large"
                      disabled={loading2}
                    >
                      {loading2 ? <Spin size="sm" /> : "Ara"}
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <div className="w-100 mt-5 mb-5 d-flex justify-content-center align-items-center">
                      <Spinner
                        style={{ width: "3rem", height: "3rem" }}
                        color="info"
                      />
                    </div>
                  ) : (
                    <Table
                      rowKey="brandId"
                      dataSource={filteredData.length > 0 ? filteredData : data}
                      rowSelection={rowSelection}
                      columns={columns}
                      pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "50", "100"],
                        showTotal: (total, range) =>
                          `${range[0]}-${range[1]} of ${total} items`,
                      }}
                      components={{
                        body: {
                          row: DraggableBodyRow,
                        },
                      }}
                      onRow={(record, index) => ({
                        index, // Dizideki gerçek indeks
                        moveRow,
                      })}
                    />
                  )}
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </motion.div>
    </DndProvider>
  );
};

export default BrandPage;
