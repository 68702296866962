import React, { useEffect, useState } from 'react';
import { Edit2, Home, PlusCircle, Trash2 } from 'react-feather';
import { Button, Card, CardBody, CardHeader, Col, Input, Row, Spinner } from 'reactstrap';
import { motion } from 'framer-motion';
import { Popconfirm, Table, Select, Button as Button2 } from 'antd';
import { toast } from 'react-toastify';
import Header from '../../components/header';
import { createFolder, deleteFolder, getFolder, updateFolder, getAllFolders } from '../../core/request';
import { mobilVariant } from '../../components/animation';
import { useNavigate } from 'react-router-dom';

const FolderPage = () => {
    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [folders, setFolders] = useState([]);
    const [formData, setFormData] = useState({
        folderName: "",
        path: "",
        parentFolderId: null,
    });

    const clearForm = () => {
        setFormData({ folderName: "", path: "", parentFolderId: null })
        setSelectedData(null)
    }

    const getDatas = async () => {
        const folders = await getAllFolders();
        if (folders.message === "Success")
            setData(folders.result);

        if (folders.message === "Success") {
            var newlist = []
            for (var i = 0; i < folders.result.length; i++) {
                if (folders.result[i] !== undefined)
                    newlist.push({ value: folders.result[i].folderId, label: folders.result[i].folderName });
            }
            setFolders(newlist);
        }
        setTimeout(() => {
            setLoading(true)
        }, 1000);
    };

    const getData = async (id) => {
        const folder = await getFolder(id);
        setSelectedData(id)
        setFormData({ folderName: folder.result.folderName, path: folder.result.path, parentFolderId: folder.result.parentFolderId })
    }

    const addData = async () => {
        let res;
        if (selectedData) {
            res = await updateFolder(selectedData, formData.folderName, formData.path, formData.parentFolderId);
        } else {
            res = await createFolder(formData.folderName, formData.path, formData.parentFolderId);
        }
        if (res.message === "Success")
            toast.success("Dosya içeriği eklendi.")
        else
            toast.error("Dosya içeriği eklenirken bir sorun oluştu!")
        getDatas()
        clearForm()
    }

    const deleteData = async (id) => {
        const folder = await deleteFolder(id);
        if (folder.message === "Success")
            toast.success("Dosya içeriği silindi.")
        else
            toast.error("Dosya içeriği silinirken bir sorun oluştu!")
        clearForm()
        getDatas()
    }

    const columns = [
        {
            title: "ID",
            key: "folderId",
            width: 30,
            dataIndex: "folderId"
        },
        {
            title: "Başlık",
            key: "folderName",
            minWidth: 50,
            render: (e) => e.folderName,
            sorter: (a, b) => a.folderName.localeCompare(b.folderName)  
        },
        {
            title: "İşlemler",
            width: 50,
            render: (e) => (
                <div className='d-flex justify-content-start'>
                    <div>
                        <Button2 className='border-warning text-warning' icon={<Edit2 size={20} />} onClick={() => getData(e.folderId)} />
                    </div>
                    <div className='ml-2'>
                        <Popconfirm
                            title="Bu dosya içeriğini silmek istediğinizden emin misiniz?"
                            onConfirm={() => deleteData(e.folderId)}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <Button2 icon={<Trash2 size={20} />} danger />
                        </Popconfirm>
                    </div>
                </div>
            )
        }
    ];

    useEffect(() => {
        getDatas();
    }, []);

    return (
        <motion.div {...mobilVariant}>
            <Row className='w-100 pb-5'>
                <Col sm={3} md={3} xl={3}>
                    <Header />
                </Col>
                <Col sm={9} md={9} xl={9}>
                    <div className='right-content mb-5'>
                        <div className='d-flex justify-content-between'>
                            <div><h3>Dosya Yönetimi</h3></div>
                            <div className='d-flex justify-content-end'>
                                <Button onClick={() => navigate("/")} className='ml-2 bg-white border border-info text-info'><Home size={20} style={{ marginTop: -5 }} /> Dashboard</Button>
                            </div>
                        </div>
                        <Card className='shadow mt-3'>
                            <CardHeader className='pl-4 shadow-sm d-flex justify-content-between align-items-center bg-white p-3' tag='h4'>Dosya Ekle</CardHeader>
                            <CardBody>
                                <Row className='p-1'>
                                    <Col md={4} className='mb-3'>
                                        <span>Dosya Adı</span>
                                        <Input
                                            type='input'
                                            value={formData.folderName}
                                            onChange={e =>
                                                setFormData({ ...formData, folderName: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>URL</span>
                                        <Input type='input'
                                            value={formData.path}
                                            onChange={e =>
                                                setFormData({ ...formData, path: e.target.value })
                                            }
                                        />
                                    </Col>
                                    <Col md={4} className='mb-3'>
                                        <span>Üst Dosya</span>
                                        <Select
                                            options={folders}
                                            className='w-100'
                                            style={{ height: 35 }}
                                            value={formData.parentFolderId}
                                            onChange={e => setFormData({ ...formData, parentFolderId: e })}
                                        />
                                    </Col>
                                    <Col md={12} className='mt-3 d-flex justify-content-end'>
                                        {selectedData ? <Button onClick={() => clearForm()} className='bg-white border-warning text-warning mr-1'>Temizle</Button> : null}
                                        <Button
                                            color={selectedData ? 'warning' : 'success'}
                                            onClick={() => addData()}
                                        >
                                            <PlusCircle size={20} style={{ marginTop: -3, marginRight: 7 }} />{selectedData ? "Güncelle" : "Ekle"}
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='shadow mt-4'>
                            <CardHeader className='pl-4 d-flex shadow-sm justify-content-between align-items-center bg-white p-3'>
                                <div><h4>Dosya Listesi</h4></div>
                            </CardHeader>
                            <CardBody>
                                {loading ? <Table
                                    rowKey='folderId'
                                    dataSource={data}
                                    columns={columns}
                                /> : <div className='w-100 mt-5 mb-5 d-flex justify-content-center align-items-center'>
                                    <Spinner style={{ width: '3rem', height: '3rem' }} color='info'> </Spinner>
                                </div>}
                            </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </motion.div>
    );
};

export default FolderPage;
