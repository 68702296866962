import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, Button, Row, Col, Input, Spinner } from 'reactstrap';
import { Switch, Table, Spin } from 'antd';
import { getAllProducts, updateProductSliderStatus, geAllProductsFilterH, getAllProductsSlider, getProduct, updateProduct } from '../../core/request';
import { toast } from 'react-toastify';
import Header from '../../components/header';
import { motion } from 'framer-motion';
import { mobilVariant } from '../../components/animation';
import { splitF } from "../../components/general";
import { useNavigate } from 'react-router-dom';

const SliderPage = () => {
    const navigate = useNavigate();
      const [data, setData] = useState([]);
      const [sliderProducts, setSliderProducts] = useState([]);     
      const [loading, setLoading] = useState(false);
      const [currentPage, setCurrentPage] = useState(1);
      const [pagination, setPagination] = useState(1);
      const [pageSize, setPageSize] = useState(10);    
      const [searchText, setSearchText] = useState("");
      const [filteredData, setFilteredData] = useState([]);
      const [loading2, setLoading2] = useState(false);


      const handleSliderToggle = async (productId, currentStatus) => {
        setLoading2(true);
        try {
            
            const productResponse = await getProduct(productId);
            
            if (productResponse?.message === "Success") {
                const product = productResponse.result;
                
              
                const res = await updateProduct(
                    productId,      // id
                    product.productName,
                    product.productNameEN,
                    product.shortDescription,
                    product.shortDescriptionEN,
                    product.content,
                    product.contentEN,
                    product.price,
                    product.discount,
                    product.discountedPrice,
                    product.additionalSpace1,
                    product.additionalSpace1EN,
                    product.additionalSpace2,
                    product.additionalSpace2EN,
                    product.additionalSpace3,
                    product.additionalSpace3EN,
                    product.barcodeNo,
                    product.lotNo,
                    product.refNo,
                    product.brandId,
                    product.imageGroupID,
                    product.unpublicfileId,
                    product.categoryId,
                    !currentStatus  // isSlider değerini tersine çevir
                );
    
                if (res?.message === "Success") {
                    toast.success(`Ürün ${!currentStatus ? 'slider\'a eklendi' : 'slider\'dan çıkarıldı'}`);
                    await getSliderProducts(); // Slider tablosunu güncelle
                    // await getNewProduct(currentPage); // Ana tabloyu güncelle
                }
            }
        } catch (error) {
            console.error("Slider toggle error:", error);
            toast.error("İşlem sırasında bir hata oluştu!");
        }
        setLoading2(false);
    };
 
    const getSliderProducts = async () => {
        setLoading(false);
        try {
            const response = await getAllProductsSlider(); // yeni endpoint gelince burası değişecek
          //  console.log("Slider Products Response:", response);
            if (response?.message === "Success") {
                setSliderProducts(response.result || []);
            }
        } catch (error) {
            toast.error("Slider ürünleri yüklenirken hata oluştu!");
        }
        setLoading(true);
    };


     const getNewProduct = async (page) => {
        setLoading(false);
        const { data: productData, pagination } = await getAllProducts("", page);
        if (productData.message === "Success") {
          setData(productData.result);
          setPageSize(pagination.PageSize || 10);
          setCurrentPage(pagination.CurrentPage || 1);
          setPagination(pagination);
        }
        setLoading(true);
      };

      const handlePageChange = (page) => {
        setCurrentPage(page);
      };


       const getDatas = async () => {
          setLoading(false);       
          await getNewProduct(1);     
         
      
          setTimeout(() => {
            setLoading(true);
          }, 1000);
        };

    // const getAllProductsData = async (page = 1, size = 10) => {
    //     setLoading(true); 
       
    //     try {
    //         const { data: productData, pagination } = await getAllProducts("", page);
    //         if (productData.message === "Success") {
    //             setAllProducts(productData.result);
    //             setPageSize(pagination.PageSize || 10);
    //             setCurrentPage(pagination.CurrentPage || 1);
    //             setPagination(pagination);
    //         }
    //     } catch (error) {
    //         toast.error("Ürünler yüklenirken hata oluştu!");
    //     }
    //     setLoading(false);
    // };

   const handleSearch = async (page=1,size=10) => {
      setLoading2(true);
      try {
        // console.log("Search Text:", searchText);
        const { data: result,pagination: apiPagination } = await geAllProductsFilterH(searchText, page, size);
        console.log("API:", pagination);
        setPageSize(apiPagination.PageSize || size);
        setCurrentPage(apiPagination.CurrentPage || page);
        setPagination(apiPagination);
        if (result?.message === "Success") {
          setFilteredData(result?.result || []);
          console.log("Filtered Data:", result.result);
        } else {
          toast.error("Ürünler getirilirken bir sorun oluştu!");
        }
      } catch (error) {
        toast.error("Ürünler getirilirken bir hata oluştu: " + error.message);
      }
      // setSearchText(""); 
      setLoading2(false);
    };

    const sliderColumns = [
        {
            title: "ID",
            dataIndex: "productId",
            key: "productId",
            width: 80,
        },
        {
            title: "Ürün Adı",
            key: "productName",
            render: (record) => splitF(record.productName, 40),
        },
        {
            title: "Açıklama",
            key: "shortDescription",
            render: (record) => splitF(record.shortDescription, 50),
        },
        {
            title: "İşlemler",
            width: 100,
            render: (record) => (
                <Button 
                    color="danger" 
                    size="sm"
                    onClick={() => handleSliderToggle(record.productId, true)}
                >
                    Kaldır
                </Button>
            ),
        },
    ];

    const productColumns = [
        {
            title: "ID",
            dataIndex: "productId",
            key: "productId",
            width: 80,
        },
        {
            title: "Ürün Adı",
            key: "productName",
            render: (record) => splitF(record.productName, 40),
        },
        {
            title: "Açıklama",
            key: "shortDescription",
            render: (record) => splitF(record.shortDescription, 50),
        },
        {
            title: "İşlemler",
            width: 100,
            render: (record) => (
                <Button 
                    color="success" 
                    size="sm"
                    onClick={() => handleSliderToggle(record.productId, false)}
                >
                    Ekle
                </Button>
            ),
        },
    ];
  

   
     useEffect(() => {
        if (searchText) {
          handleSearch(currentPage, pageSize || 10);
        } else {
          getNewProduct(currentPage);
        }
      }, [currentPage]);
    
      useEffect(() => {
        getSliderProducts();
        getDatas();
      }, []);

    return (
        <motion.div {...mobilVariant}>
            <Row className="w-100 pb-5">
                <Col sm={3} md={3} xl={3}>
                    <Header />
                </Col>
                <Col sm={9} md={9} xl={9}>
                    <div className="right-content mb-5">
                        <Card className="shadow mb-4">
                            <CardHeader className="bg-white">
                                <h4>Slider Ürünleri</h4>
                            </CardHeader>
                            <CardBody>
                                <Table
                                    columns={sliderColumns}
                                    dataSource={sliderProducts}
                                    // loading={loading}
                                    pagination={false}
                                    scroll={{ x: "max-content" }}
                                />
                            </CardBody>
                        </Card>

                        <Card className="shadow">
                            <CardHeader className="bg-white d-flex justify-content-between align-items-center">
                                <h4>Tüm Ürünler</h4>
                                <div className="d-flex align-items-center">
                                    <Input
                                        placeholder="Ürün Ara..."
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                    <Button
                                        color="success"
                                        className="ml-2"
                                        onClick={() => handleSearch()}
                                    >
                                         {loading2 ? <Spin size="sm" /> : "Ara"}
                                    </Button>
                                </div>
                            </CardHeader>
                             <CardBody>
                                           {loading ? (
                                             <Table
                                               rowKey="productId"
                                               dataSource={filteredData.length > 0 ? filteredData : data}
                                            //    rowSelection={rowSelection}
                                               loading={loading2}
                                               columns={productColumns}
                                               scroll={{ x: "max-content" }}
                                               pagination={{
                                                 total: pagination?.TotalCount || 0,
                                                 pageSize: pageSize || 10,
                                                 current: currentPage || 1,
                                                 showSizeChanger: true,
                                                 pageSizeOptions: ['10', '20', '25', '50'],
                                                 onChange: (page, size) => {                          
                                                     setCurrentPage(page);
                                                     setPageSize(size);
                                                     if (filteredData.length > 0) {
                                                       handleSearch(page, size);
                                                     } else {
                                                       getNewProduct(page, size); 
                                                     };
                                                 },
                                                 onShowSizeChange: (current, size) => {
                                                     setPageSize(size);
                                                     setCurrentPage(1);
                                                     if (filteredData.length > 0) {
                                                       setFilteredData([]);
                                                       handleSearch(1, size);
                                                     } else {
                                                       getNewProduct(1, size);
                                                     }
                                                 }
                                               }}
                                             />
                                           ) : (
                                             <div className="w-100 mt-5 mb-5 d-flex justify-content-center align-items-center">
                                               <Spinner
                                                 style={{ width: "3rem", height: "3rem" }}
                                                 color="info"
                                               >
                                                 {" "}
                                               </Spinner>
                                             </div>
                                           )}
                                         </CardBody>
                        </Card>
                    </div>
                </Col>
            </Row>
        </motion.div>
    );
};

export default SliderPage;