import axios from "axios";
import {
  ADVERT_CREATE,
  ADVERT_DELETE,
  ADVERT_GET,
  ADVERT_GETALL,
  ADVERT_UPDATE,
  AUTH_LOGIN,
  AUTH_REGISTER,
  BASKET_CREATE,
  BASKET_DELETE,
  BASKET_GET,
  BASKET_GETALL,
  BASKET_UPDATE,
  BLOG_CREATE,
  BLOG_DELETE,
  BLOG_GET,
  BLOG_GETALL,
  BLOG_UPDATE,
  BOOKCASE_CREATE,
  BOOKCASE_DELETE,
  BOOKCASE_GET,
  BOOKCASE_GETALL,
  BRAND_ALL,
  BRAND_CREATE,
  BRAND_DELETE,
  BRAND_GET,
  BRAND_GETALL,
  BRAND_GETPARAMS,
  BRAND_SORT,
  BRAND_UPDATE,
  CATEGORY_ALL,
  CATEGORY_CREATE,
  CATEGORY_DELETE,
  CATEGORY_GET,
  CATEGORY_GETALL,
  CATEGORY_GETPARAMS,
  CATEGORY_SORT,
  CATEGORY_UPDATE,
  CONTACT_GET,
  CONTACT_UPDATE,
  FAVORITE_CREATE,
  FAVORITE_DELETE,
  FAVORITE_GET,
  FAVORITE_GETALL,
  FILE_CREATE,
  FILE_DELETE,
  FILE_GET,
  FILE_GETALL,
  FILE_UPDATE,
  FOLDER_CREATE,
  FOLDER_DELETE,
  FOLDER_GET,
  FOLDER_GETALL,
  FOLDER_UPDATE,
  FORM_CREATE,
  FORM_DELETE,
  FORM_GET,
  FORM_GETALL,
  FORM_UPDATE,
  FORMCATEGORY_CREATE,
  FORMCATEGORY_DELETE,
  FORMCATEGORY_GET,
  FORMCATEGORY_GETALL,
  FORMCATEGORY_UPDATE,
  FORMELEMENT_CREATE,
  FORMELEMENT_DELETE,
  FORMELEMENT_GET,
  FORMELEMENT_GETALL,
  FORMELEMENT_UPDATE,
  FORMRESPONSE_CREATE,
  FORMRESPONSE_DELETE,
  FORMRESPONSE_GET,
  FORMRESPONSE_GETALL,
  FORMRETURN_CREATE,
  FORMRETURN_DELETE,
  FORMRETURN_GET,
  FORMRETURN_GETALL,
  IMAGE_CREATE,
  IMAGE_DELETE,
  IMAGE_GET,
  IMAGE_GETALL,
  IMAGE_GETALLBYID,
  IMAGE_UPDATE,
  IMAGEGROUP_ALL,
  IMAGEGROUP_CREATE,
  IMAGEGROUP_DELETE,
  IMAGEGROUP_GET,
  IMAGEGROUP_GETALL,
  IMAGEGROUP_UPDATE,
  KID_CREATE,
  KID_DELETE,
  KID_GET,
  KID_GETALL,
  KID_GETALLBYMOM,
  KID_UPDATE,
  MEDIA_CREATE,
  MEDIA_DELETE,
  MEDIA_GET,
  MEDIA_GETALL,
  MEDIA_UPDATE,
  PAGE_CREATE,
  PAGE_DELETE,
  PAGE_GET,
  PAGE_GETALL,
  PAGE_UPDATE,
  POPUP_CREATE,
  POPUP_DELETE,
  POPUP_GET,
  POPUP_GETALL,
  POPUP_UPDATE,
  PRODUCT_CREATE,
  PRODUCT_DELETE,
  PRODUCT_EXCELIMPORT,
  PRODUCT_GET,
  PRODUCT_GETALL,
  PRODUCT_GETALLSLİDER,
  PRODUCT_UPDATE,
  PRODUCTCATEGORY_CREATE,
  PRODUCTCATEGORY_DELETE,
  PRODUCTCATEGORY_GET,
  PRODUCTCATEGORY_GETALL,
  PRODUCTCATEGORY_UPDATE,
  PROMOTION_CREATE,
  PROMOTION_DELETE,
  PROMOTION_GET,
  PROMOTION_GETALL,
  PROMOTION_UPDATE,
  SETTING_CREATE,
  SETTING_DELETE,
  SETTING_GET,
  SETTING_GETALL,
  SETTING_UPDATE,
  SOCIALMEDIA_CREATE,
  SOCIALMEDIA_DELETE,
  SOCIALMEDIA_GET,
  SOCIALMEDIA_GETALL,
  SOCIALMEDIA_UPDATE,
  SSS_CREATE,
  SSS_DELETE,
  SSS_GET,
  SSS_GETALL,
  SSS_UPDATE,
  UNPUBLICFILE_CREATE,
  UNPUBLICFILE_DELETE,
  UNPUBLICFILE_GET,
  UNPUBLICFILE_GETALL,
  UNPUBLICFILE_GETALLBYID,
  UNPUBLICFILE_UPDATE,
  UNPUBLICFILEGROUP_CREATE,
  UNPUBLICFILEGROUP_DELETE,
  UNPUBLICFILEGROUP_GET,
  UNPUBLICFILEGROUP_GETALL,
  UNPUBLICFILEGROUP_UPDATE,
  USER_CHANGEPASSWORD,
  USER_DELETE,
  USER_GET,
  USER_GETALL,
  USER_UPDATE,
} from "../api";
import { toast } from "react-toastify";

const token =
  localStorage.getItem("auth") === null
    ? null
    : JSON.parse(localStorage.getItem("auth")).accessToken;
const userId =
  localStorage.getItem("auth") === null
    ? null
    : JSON.parse(localStorage.getItem("auth")).userId;
const header = { headers: { Authorization: `Bearer ${token}` } };

// Advert
export function getAllAdverts() {
  return axios
    .get(ADVERT_GETALL, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getAdvert(id) {
  return axios
    .get(`${ADVERT_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createAdvert(
  title,
  titleEN,
  description,
  descriptionEN,
  imageGroupID
) {
  return axios
    .post(
      ADVERT_CREATE,
      {
        title: title,
        titleEN: titleEN,
        description: description,
        descriptionEN: descriptionEN,
        imageGroupID: imageGroupID,
        userId: userId,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updateAdvert(
  id,
  title,
  titleEN,
  description,
  descriptionEN,
  imageGroupID
) {
  return axios
    .put(
      `${ADVERT_UPDATE}/${id}`,
      {
        title: title,
        titleEN: titleEN,
        description: description,
        descriptionEN: descriptionEN,
        imageGroupID: imageGroupID,
        userId: userId,
        advertID: id,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deleteAdvert(id) {
  return axios
    .delete(`${ADVERT_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// Advert END

// Page
export function getAllPages(search) {
  return axios
    .get(
      `${PAGE_GETALL}?searchTerm=${search}&pageNumber=1&pageSize=100`,
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getPage(id) {
  return axios
    .get(`${PAGE_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createPage(
  header2,
  headerEN,
  shortDescription,
  shortDescriptionEN,
  seoUrl,
  seoUrlEN,
  content,
  contentEN,
  tags,
  tagsEN,
  additionalSpace1,
  additionalSpace2,
  additionalSpace3
) {
  return axios
    .post(
      PAGE_CREATE,
      {
        header: header2,
        headerEN: headerEN,
        shortDescription: shortDescription,
        shortDescriptionEN: shortDescriptionEN,
        seoUrl: seoUrl,
        seoUrlEN: seoUrlEN,
        content: content,
        contentEN: contentEN,
        tags: tags,
        tagsEN: tagsEN,
        additionalSpace1: additionalSpace1,
        additionalSpace2: additionalSpace2,
        additionalSpace3: additionalSpace3,
        userId: userId,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updatePage(
  id,
  header2,
  headerEN,
  shortDescription,
  shortDescriptionEN,
  seoUrl,
  seoUrlEN,
  content,
  contentEN,
  tags,
  tagsEN,
  additionalSpace1,
  additionalSpace2,
  additionalSpace3
) {
  return axios
    .put(
      `${PAGE_UPDATE}/${id}`,
      {
        header: header2,
        headerEN: headerEN,
        shortDescription: shortDescription,
        shortDescriptionEN: shortDescriptionEN,
        seoUrl: seoUrl,
        seoUrlEN: seoUrlEN,
        content: content,
        contentEN: contentEN,
        tags: tags,
        tagsEN: tagsEN,
        additionalSpace1: additionalSpace1,
        additionalSpace2: additionalSpace2,
        additionalSpace3: additionalSpace3,
        userId: userId,
        pageID: id,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deletePage(id) {
  return axios
    .delete(`${PAGE_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// Page END

// Authentication
export function login(userName, password) {
  return axios
    .post(AUTH_LOGIN, { userName: userName, password: password })
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function register(
  firstName,
  lastName,
  userName,
  email,
  phoneNumber,
  password,
  roles
) {
  return axios
    .post(AUTH_REGISTER, {
      firstName: firstName,
      lastName: lastName,
      userName: userName,
      email: email,
      phoneNumber: phoneNumber,
      password: password,
      roles: roles,
    })
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// Authentication END

// Basket
export function getAllBaskets(userId) {
  return axios
    .get(`${BASKET_GETALL}/${userId}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getBasket(id) {
  return axios
    .get(`${BASKET_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createBasket(productID, piece) {
  return axios
    .post(
      BASKET_CREATE,
      { productID: productID, piece: piece, userId: userId },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updateBasket(id, productID, piece) {
  return axios
    .put(
      `${BASKET_UPDATE}/${id}`,
      {
        productID: productID,
        piece: piece,
        userId: userId,
        userId: userId,
        basketID: id,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deleteBasket(id) {
  return axios
    .delete(`${BASKET_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// Basket END

// BookCase
export function getAllBookCases(userId) {
  return axios
    .get(`${BOOKCASE_GETALL}/${userId}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getBookCase(id) {
  return axios
    .get(`${BOOKCASE_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createBookCase(productID) {
  return axios
    .post(BOOKCASE_CREATE, { productID: productID, userId: userId }, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deleteBookCase(id) {
  return axios
    .delete(`${BOOKCASE_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// BookCase END

// Category
export function getAllCategories() {
  return axios
    .get(CATEGORY_GETALL, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getAllCategoriesAll() {
  return axios
    .get(CATEGORY_ALL, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
export function getCategoriesParams(searchText = "") {
  return axios
    .get(`${CATEGORY_GETPARAMS}?search=${searchText}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getCategory(id) {
  return axios
    .get(`${CATEGORY_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function sortCategories(categoryId, sortOrder) {
  const payload = {
    id: Number(categoryId),
    sort: Number(sortOrder),
  };

  return axios
    .put(`${CATEGORY_SORT}?id=${categoryId}&sort=${sortOrder}`, null, header)
    .then((res) => {
      console.log("Sort API response:", res);
      return res.data;
    })
    .catch((error) => {
      console.error("Sort API error:", error.response?.data || error);
      throw error;
    });
}

export function createCategory(name, nameEN, imageGroupID, parentCategoryID) {
  return axios
    .post(
      CATEGORY_CREATE,
      {
        name: name,
        nameEN: nameEN,
        shortDescription: "",
        shortDescriptionEN: "",
        imageGroupID: imageGroupID,
        parentCategoryID: parentCategoryID,
        userId: userId,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
export function createCategoryN(name, nameEN, imageGroupID, parentCategoryID,files=[]) {
    const formData = new FormData();
    formData.append("name", name || "");
    formData.append("nameEN", nameEN || "");
    // formData.append("shortDescription", desc  || "");
    // formData.append("shortDescriptionEN",descEN  || "");
    if (imageGroupID != null && imageGroupID != undefined)
        formData.append("ImageGroupID", imageGroupID);
    // formData.append("parentCategoryID", parentCategoryID  || "");
    formData.append("userId", userId);
    if (files && files.length > 0) {
        files.forEach((file) => {
          formData.append("file", file); 
        });
      }

  return axios
    .post(
      CATEGORY_CREATE,formData,{
        ...header,
        headers: {
          ...header.headers,
          "Content-Type": "multipart/form-data",
        },
      }      
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updateCategory(
  id,
  name,
  nameEN,
  imageGroupID,
  parentCategoryID
) {
  return axios
    .put(
      `${CATEGORY_UPDATE}/${id}`,
      {
        name: name,
        nameEN: nameEN,
        shortDescription: "",
        shortDescriptionEN: "",
        imageGroupID: imageGroupID,
        parentCategoryID: parentCategoryID,
        categoryID: id,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
export function updateCategoryN(
  id,
  name,
  nameEN,
  imageGroupID,
  parentCategoryID,
  files=[]
) {
    const formData = new FormData();
    formData.append("categoryID", id);
    formData.append("name", name || "");
    formData.append("nameEN", nameEN || "");   
    if (imageGroupID !== null && imageGroupID !== undefined) {
        formData.append("ImageGroupID", imageGroupID);
    }
    
    if (parentCategoryID !== null && parentCategoryID !== undefined) {
        formData.append("ParentCategoryId", parentCategoryID);
    }
    formData.append("userId", userId);
    if (files && files.length > 0) {
        files.forEach((file) => {
          formData.append("file", file); 
        });
      }
  return axios
    .put(
      CATEGORY_UPDATE,formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
          
        },
      })
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deleteCategory(id) {
  return axios
    .delete(`${CATEGORY_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// Category END

// Favorite
export function getAllFavorites(userId) {
  return axios
    .get(`${FAVORITE_GETALL}/${userId}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getFavorite(id) {
  return axios
    .get(`${FAVORITE_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createFavorite(productID) {
  return axios
    .post(FAVORITE_CREATE, { productID: productID, userId: userId }, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deleteFavorite(id) {
  return axios
    .delete(`${FAVORITE_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// Favorite END

// Folder
export function getAllFolders() {
  return axios
    .get(FOLDER_GETALL, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getFolder(id) {
  return axios
    .get(`${FOLDER_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createFolder(folderName, path, parentFolderId) {
  return axios
    .post(
      FOLDER_CREATE,
      {
        folderName: folderName,
        path: path,
        parentFolderId: parentFolderId,
        userId: userId,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updateFolder(id, folderName, path, parentFolderId) {
  return axios
    .put(
      `${FOLDER_UPDATE}/${id}`,
      {
        folderName: folderName,
        path: path,
        parentFolderId: parentFolderId,
        userId: userId,
        folderId: id,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deleteFolder(id) {
  return axios
    .delete(`${FOLDER_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// Folder END

// Form
export function getAllForms() {
  return axios
    .get(FORM_GETALL, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getForm(id) {
  return axios
    .get(`${FORM_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createForm(title, titleEN, description, descriptionEN) {
  return axios
    .post(
      FORM_CREATE,
      {
        title: title,
        titleEN: titleEN,
        description: description,
        descriptionEN: descriptionEN,
        userId: userId,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updateForm(id, title, titleEN, description, descriptionEN) {
  return axios
    .put(
      `${FORM_UPDATE}/${id}`,
      {
        title: title,
        titleEN: titleEN,
        description: description,
        descriptionEN: descriptionEN,
        userId: userId,
        formID: id,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deleteForm(id) {
  return axios
    .delete(`${FORM_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// Form END

// FormCategory
export function getAllFormCategories(id) {
  return axios
    .get(`${FORMCATEGORY_GETALL}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getFormCategory(id) {
  return axios
    .get(`${FORMCATEGORY_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createFormCategory(title, titleEN, desc, descEN, formID) {
  return axios
    .post(
      FORMCATEGORY_CREATE,
      {
        title: title,
        titleEN: titleEN,
        description: desc,
        descriptionEN: descEN,
        formID: formID,
        userId: userId,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updateFormCategory(
  id,
  title,
  titleEN,
  desc,
  descEN,
  formType,
  formID
) {
  return axios
    .put(
      `${FORMCATEGORY_UPDATE}/${id}`,
      {
        title: title,
        titleEN: titleEN,
        description: desc,
        descriptionEN: descEN,
        formID: formID,
        userId: userId,
        formElementID: id,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deleteFormCategory(id) {
  return axios
    .delete(`${FORMCATEGORY_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// FormElement END

// FormElement
export function getAllFormElements(id) {
  return axios
    .get(`${FORMELEMENT_GETALL}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getFormElement(id) {
  return axios
    .get(`${FORMELEMENT_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createFormElement(title, titleEN, formType, formID) {
  return axios
    .post(
      FORMELEMENT_CREATE,
      {
        title: title,
        titleEN: titleEN,
        formType: formType,
        formID: formID,
        userId: userId,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updateFormElement(id, title, titleEN, formType, formID) {
  return axios
    .put(
      `${FORMELEMENT_UPDATE}/${id}`,
      {
        title: title,
        titleEN: titleEN,
        formType: formType,
        formID: formID,
        userId: userId,
        formElementID: id,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deleteFormElement(id) {
  return axios
    .delete(`${FORMELEMENT_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// FormElement END

// FormResponse
export function getAllFormResponses(userId) {
  return axios
    .get(`${FORMRESPONSE_GETALL}/${userId}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getFormResponse(id) {
  return axios
    .get(`${FORMRESPONSE_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createFormResponse(response, formID) {
  return axios
    .post(
      FORMRESPONSE_CREATE,
      { response: response, formID: formID, userId: userId },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deleteFormResponse(id) {
  return axios
    .delete(`${FORMRESPONSE_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// FormResponse END

// FormReturn
export function getAllFormReturns() {
  return axios
    .get(FORMRETURN_GETALL, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getAllFormReturnsByClient(userId) {
  return axios
    .get(`${FORMRETURN_GETALL}/${userId}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getFormReturn(id) {
  return axios
    .get(`${FORMRETURN_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createFormReturn(
  title,
  description,
  content,
  formID,
  clientId
) {
  return axios
    .post(
      FORMRETURN_CREATE,
      {
        title: title,
        description: description,
        content: content,
        formID: formID,
        clientId: clientId,
        userId: userId,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deleteFormReturn(id) {
  return axios
    .delete(`${FORMRETURN_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// FormReturn END

// Image
export function getAllImages() {
  return axios
    .get(IMAGE_GETALL, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getAllImagesById(id) {
  return axios
    .get(`${IMAGE_GETALLBYID}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getImage(id) {
  return axios
    .get(`${IMAGE_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createImage(
  file,
  field1,
  field1EN,
  field2,
  field2EN,
  field3,
  field3EN,
  imageGroupID
) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("AdditionalField1", field1);
  formData.append("AdditionalField1EN", field1EN);
  formData.append("AdditionalField2", field2);
  formData.append("AdditionalField2EN", field2EN);
  formData.append("AdditionalField3", field3);
  formData.append("AdditionalField3EN", field3EN);
  formData.append("ImageGroupID", imageGroupID);
  formData.append("UserId", userId);

  return axios
    .post(IMAGE_CREATE, formData, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updateImage(
  id,
  file,
  field1,
  field1EN,
  field2,
  field2EN,
  field3,
  field3EN,
  imageGroupID
) {
  const formData = new FormData();
  formData.append("ImageID", id);
  formData.append("file", file === undefined ? null : file);
  formData.append("AdditionalField1", field1);
  formData.append("AdditionalField1EN", field1EN);
  formData.append("AdditionalField2", field2);
  formData.append("AdditionalField2EN", field2EN);
  formData.append("AdditionalField3", field3);
  formData.append("AdditionalField3EN", field3EN);
  formData.append("ImageGroupID", imageGroupID);
  formData.append("UserId", userId);

  return axios
    .post(IMAGE_UPDATE, formData, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deleteImage(id) {
  return axios
    .delete(`${IMAGE_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// Image END

// ImageGroup
export function getAllImageGroups() {
  return axios
    .get(IMAGEGROUP_GETALL, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getAllImageGroupsAll() {
  return axios
    .get(IMAGEGROUP_ALL, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getImageGroup(id) {
  return axios
    .get(`${IMAGEGROUP_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createImageGroup(title, titleEN, description, descriptionEN) {
  return axios
    .post(
      IMAGEGROUP_CREATE,
      {
        title: title,
        titleEN: titleEN,
        description: description,
        descriptionEN: descriptionEN,
        userId: userId,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updateImageGroup(
  id,
  title,
  titleEN,
  description,
  descriptionEN
) {
  return axios
    .put(
      `${IMAGEGROUP_UPDATE}/${id}`,
      {
        title: title,
        titleEN: titleEN,
        description: description,
        descriptionEN: descriptionEN,
        userId: userId,
        imageGroupID: id,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deleteImageGroup(id) {
  return axios
    .delete(`${IMAGEGROUP_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// ImageGroup END

//Fİle services

export function getAllFiles() {
  return axios
    .get(`${FILE_GETALL}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getFile(id) {
  return axios
    .get(`${FILE_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
export function createFile(
  file,
  fileName,
  filePath,
  additionalField1,
  additionalField1EN,
  additionalField2,
  additionalField2EN,
  additionalField3,
  additionalField3EN,
  expirationDate,
  folderId,
  mediaId
) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("FileName", fileName);
  formData.append("FilePath", filePath);
  formData.append("AdditionalField1", additionalField1 || "");
  formData.append("AdditionalField1EN", additionalField1EN || "");
  formData.append("AdditionalField2", additionalField2 || "");
  formData.append("AdditionalField2EN", additionalField2EN || "");
  formData.append("AdditionalField3", additionalField3 || "");
  formData.append("AdditionalField3EN", additionalField3EN || "");
  formData.append("ExpirationDate", expirationDate || new Date().toISOString());
  formData.append("FolderId", folderId || 0);
  formData.append("MediaId", mediaId || 0);
  formData.append("UserId", userId);

  return axios
    .post(FILE_CREATE, formData, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updateFile(
  id,
  file,
  fileName,
  filePath,
  additionalField1,
  additionalField1EN,
  additionalField2,
  additionalField2EN,
  additionalField3,
  additionalField3EN,
  expirationDate,
  folderId,
  mediaId
) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("FileName", fileName);
  formData.append("FilePath", filePath);
  formData.append("AdditionalField1", additionalField1 || "");
  formData.append("AdditionalField1EN", additionalField1EN || "");
  formData.append("AdditionalField2", additionalField2 || "");
  formData.append("AdditionalField2EN", additionalField2EN || "");
  formData.append("AdditionalField3", additionalField3 || "");
  formData.append("AdditionalField3EN", additionalField3EN || "");
  formData.append("ExpirationDate", expirationDate || new Date().toISOString());
  formData.append("FolderId", folderId || 0);
  formData.append("MediaId", mediaId || 0);
  formData.append("UserId", userId);
  formData.append("FileId", id);

  return axios
    .put(`${FILE_UPDATE}/${id}`, formData, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deleteFile(id) {
  return axios
    .delete(`${FILE_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// File END

// UnPublicFile
export function getAllUnPublicFiles(search) {
  return axios
    .get(
      `${UNPUBLICFILE_GETALL}?searchTerm=${search}&pageNumber=1&pageSize=100`,
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getUnPublicFile(id) {
  return axios
    .get(`${UNPUBLICFILE_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createUnPublicFile(
  file,
  FileNumber,
  ExpirationDate,
  AdditionalField1,
  AdditionalField1EN,
  AdditionalField2,
  AdditionalField2EN,
  AdditionalField3,
  AdditionalField3EN,
  ReferanceNo,
  IsUnPublic,
  LotNo,
  ProductId,
  FolderId
) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("FileNumber", FileNumber);
  formData.append("ExpirationDate", ExpirationDate);
  formData.append("AdditionalField1", AdditionalField1);
  formData.append("AdditionalField1EN", AdditionalField1EN);
  formData.append("AdditionalField2", AdditionalField2);
  formData.append("AdditionalField2EN", AdditionalField2EN);
  formData.append("AdditionalField3", AdditionalField3);
  formData.append("AdditionalField3EN", AdditionalField3EN);
  formData.append("ReferanceNo", ReferanceNo);
  formData.append("IsUnPublic", IsUnPublic);
  formData.append("LotNo", LotNo);
  formData.append("UserId", userId);

  return axios
    .post(UNPUBLICFILE_CREATE, formData, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updateUnPublicFile(
  id,
  file,
  FileNumber,
  ExpirationDate,
  AdditionalField1,
  AdditionalField1EN,
  AdditionalField2,
  AdditionalField2EN,
  AdditionalField3,
  AdditionalField3EN,
  ReferanceNo,
  IsUnPublic,
  LotNo,
  ProductId,
  FolderId
) {
  const formData = new FormData();
  formData.append("ImageID", id);
  formData.append("file", file === undefined ? null : file);
  formData.append("FileNumber", FileNumber);
  formData.append("ExpirationDate", ExpirationDate);
  formData.append("AdditionalField1", AdditionalField1);
  formData.append("AdditionalField1EN", AdditionalField1EN);
  formData.append("AdditionalField2", AdditionalField2);
  formData.append("AdditionalField2EN", AdditionalField2EN);
  formData.append("AdditionalField3", AdditionalField3);
  formData.append("AdditionalField3EN", AdditionalField3EN);
  formData.append("ReferanceNo", ReferanceNo);
  formData.append("IsUnPublic", IsUnPublic);
  formData.append("LotNo", LotNo);
  formData.append("UserId", userId);

  return axios
    .put(UNPUBLICFILE_UPDATE, formData, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deleteUnPublicFile(id) {
  return axios
    .delete(`${UNPUBLICFILE_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// UnPublicFile END

// UnPublicFileGroup
export function getAllUnPublicFileGroups() {
  return axios
    .get(UNPUBLICFILEGROUP_GETALL, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getUnPublicFileGroup(id) {
  return axios
    .get(`${UNPUBLICFILEGROUP_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createUnPublicFileGroup(
  title,
  titleEN,
  description,
  descriptionEN
) {
  return axios
    .post(
      UNPUBLICFILEGROUP_CREATE,
      {
        title: title,
        titleEN: titleEN,
        description: description,
        descriptionEN: descriptionEN,
        userId: userId,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updateUnPublicFileGroup(
  id,
  title,
  titleEN,
  description,
  descriptionEN
) {
  return axios
    .put(
      `${UNPUBLICFILEGROUP_UPDATE}/${id}`,
      {
        title: title,
        titleEN: titleEN,
        description: description,
        descriptionEN: descriptionEN,
        userId: userId,
        unPublicFileGroupID: id,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deleteUnPublicFileGroup(id) {
  return axios
    .delete(`${UNPUBLICFILEGROUP_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// UnPublicFileGroup END

// Kid
export function getAllKids() {
  return axios
    .get(KID_GETALL, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getAllKidsByMom(id) {
  return axios
    .get(`${KID_GETALLBYMOM}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getKid(id) {
  return axios
    .get(`${KID_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createKid(fullName, isWoman, momID, birthDay) {
  return axios
    .post(
      KID_CREATE,
      {
        fullName: fullName,
        isWoman: isWoman,
        momID: momID,
        birthDay: birthDay,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updateKid(id, fullName, isWoman, momID, birthDay) {
  return axios
    .put(
      `${KID_UPDATE}/${id}`,
      {
        fullName: fullName,
        isWoman: isWoman,
        momID: momID,
        birthDay: birthDay,
        kidID: id,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deleteKid(id) {
  return axios
    .delete(`${KID_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// Kid END

// Blog
export function getAllBlogs(search) {
  return axios
    .get(
      `${BLOG_GETALL}?searchTerm=${search}&pageNumber=1&pageSize=100`,
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getBlog(id) {
  return axios
    .get(`${BLOG_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createBlog(
  title,
  titleEN,
  desc,
  descEN,
  url,
  urlEN,
  content,
  contentEN,
  imageGroupId,
  mediaId
) {
  return axios
    .post(
      BLOG_CREATE,
      {
        title: title,
        titleEN: titleEN,
        shortDescription: desc,
        shortDescriptionEN: descEN,
        seoUrl: url,
        seoUrlEN: urlEN,
        content: content,
        contentEN: contentEN,
        imageGroupID: imageGroupId,
        mediaId: mediaId,
        userId: userId,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
export function createBlogN(
  title,
  titleEN,
  desc,
  descEN,
  url,
  urlEN,
  content,
  contentEN,
  imageGroupId,
  mediaId,
  files = []
) {
  const formData = new FormData();
  formData.append("title", title || "");
  formData.append("titleEN", titleEN || "");
  formData.append("shortDescription", desc || "");
  formData.append("shortDescriptionEN", descEN || "");
  formData.append("seoUrl", url || "");
  formData.append("seoUrlEN", urlEN || "");
  formData.append("content", content || "");
  formData.append("contentEN", contentEN || "");
  if (imageGroupId != null && imageGroupId != undefined)
    formData.append("imageGroupID", imageGroupId);
  if (mediaId != null && mediaId != undefined){
    formData.append("mediaId", mediaId);
  }
  formData.append("userId", userId);
  if (files && files.length > 0) {
    files.forEach((file) => {
      formData.append("file", file); 
    });
  }
  return axios
    .post(
      BLOG_CREATE,formData,{
        ...header,
        headers: {
          ...header.headers,
          "Content-Type": "multipart/form-data",
        },
      }     
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updateBlog(
  id,
  title,
  titleEN,
  desc,
  descEN,
  url,
  urlEN,
  content,
  contentEN,
  imageGroupId,
  mediaId
) {
  return axios
    .put(
      `${BLOG_UPDATE}/${id}`,
      {
        title: title,
        titleEN: titleEN,
        shortDescription: desc,
        shortDescriptionEN: descEN,
        seoUrl: url,
        seoUrlEN: urlEN,
        content: content,
        contentEN: contentEN,
        imageGroupID: imageGroupId,
        mediaId: mediaId,
        userId: userId,
        blogId: id,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
export function updateBlogN(
  id,
  title,
  titleEN,
  desc,
  descEN,
  url,
  urlEN,
  content,
  contentEN,
  imageGroupId,
  mediaId,
  files
) {
  const formData = new FormData();
  formData.append("title", title || "");
  formData.append("titleEN", titleEN || "");
  formData.append("shortDescription", desc || "");
  formData.append("shortDescriptionEN", descEN || "");
  formData.append("seoUrl", url || "");
  formData.append("seoUrlEN", urlEN || "");
  formData.append("content", content || "");
  formData.append("contentEN", contentEN || "");
  if (imageGroupId != null && imageGroupId != undefined)
    formData.append("imageGroupID", imageGroupId);
  if (mediaId != null && mediaId != undefined){
    formData.append("mediaId", mediaId);
  }
  formData.append("userId", userId);
  formData.append("blogId", id);
  if (files && files.length > 0) {
    files.forEach((file) => {
      formData.append("file", file); 
    });
  }

  return axios
    .put(
      BLOG_UPDATE,formData,{
        ...header,
        headers: {
          ...header.headers,
          "Content-Type": "multipart/form-data",
        },
      }     
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deleteBlog(id) {
  return axios
    .delete(`${BLOG_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// Blog END

// Brand
export function getAllBrands() {
  return axios
    .get(BRAND_GETALL, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getAllBrandsAll() {
  return axios
    .get(BRAND_ALL, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getBrand(id) {
  return axios
    .get(`${BRAND_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getBrandParams(searchText = "") {
  return axios
    .get(`${BRAND_GETPARAMS}?search=${searchText}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createBrand(
  brandName,
  brandNameEN,
  desc,
  descEN,
  content,
  contentEN,
  imageGroupId
) {
  return axios
    .post(
      BRAND_CREATE,
      {
        brandName: brandName,
        brandNameEN: brandNameEN,
        shortDescription: desc,
        shortDescriptionEN: descEN,
        content: content,
        contentEN: contentEN,
        imageGroupID: imageGroupId,
        userId: userId,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createBrandN(
  brandName,
  brandNameEN,
  desc,
  descEN,
  content,
  contentEN,
  imageGroupId,
  files = []
) {
  const formData = new FormData();
  formData.append("brandName", brandName || "");
  formData.append("brandNameEN", brandNameEN || "");
  formData.append("shortDescription", desc || "");
  formData.append("shortDescriptionEN", descEN || "");
  formData.append("content", content || "");
  formData.append("contentEN", contentEN || "");
  if (imageGroupId != null && imageGroupId != undefined)
   formData.append("imageGroupID", imageGroupId);
  formData.append('userId', userId);

  if (files && files.length > 0) {
    files.forEach((file) => {
      formData.append("file", file); 
    });
  }

  return axios
    .post(BRAND_CREATE, formData, {
      ...header,
      headers: {
        ...header.headers,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updateBrand(
  id,
  brandName,
  brandNameEN,
  desc,
  descEN,
  content,
  contentEN,
  imageGroupId
) {
  return axios
    .put(
      `${BRAND_UPDATE}/${id}`,
      {
        brandName: brandName,
        brandNameEN: brandNameEN,
        shortDescription: desc,
        shortDescriptionEN: descEN,
        content: content,
        contentEN: contentEN,
        imageGroupID: imageGroupId,
        imageGroupID: imageGroupId,
        userId: userId,
        brandId: id,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updateBrandN(
  id,
  brandName,
  brandNameEN,
  desc,
  descEN,
  content,
  contentEN,
  imageGroupId,
  files
) {
  const formData = new FormData();

  formData.append("BrandId", id);
  formData.append("BrandName", brandName);
  formData.append("BrandNameEN", brandNameEN);
  formData.append("ShortDescription", desc);
  formData.append("ShortDescriptionEN", descEN);
  formData.append("Content", content);
  formData.append("ContentEN", contentEN);
  if (imageGroupId != null && imageGroupId != undefined)
    formData.append("ImageGroupID", imageGroupId);
  formData.append("UserId", userId);

  // Append files
  if (files && files.length > 0) {
    files.forEach((file) => {
      formData.append("file", file); 
    });
  }

  // Log FormData contents before sending
  console.log("FormData contents:");
  for (const pair of formData.entries()) {
    console.log(pair[0] + ":", pair[1]);
  }

  return axios
    .put(`${BRAND_UPDATE}/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      console.log("Update successful:", res.data);
      return res.data;
    })
    .catch((error) => {
      console.error("Update error:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
        toast.error(
          error.response?.data?.message ||
            "Marka güncellenirken bir hata oluştu!"
        );
      } else if (error.request) {
        console.error("Request:", error.request);
        toast.error("İstek gönderilirken bir hata oluştu!");
      } else {
        console.error("Error message:", error.message);
        toast.error("Bilinmeyen bir hata oluştu!");
      }
      throw error;
    });
}

export function sortBrand(brandId, sortOrder) {
  const payload = {
    id: Number(brandId),
    sort: Number(sortOrder),
  };

  return axios
    .put(
      `${BRAND_SORT}?id=${brandId}&sort=${sortOrder}`,
      null, // Body gönderilmiyor
      header
    )
    .then((res) => {
      console.log("Sort API response:", res);
      return res.data;
    })
    .catch((error) => {
      console.error("Sort API error:", {
        status: error.response?.status,
        data: error.response?.data,
        url: error.config?.url,
      });
      toast.error("Sıralama işlemi başarısız!");
      throw error;
    });
}

export function deleteBrand(id) {
  return axios
    .delete(`${BRAND_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// Brand END

// Media
export function getAllMedias() {
  return axios
    .get(MEDIA_GETALL, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getMedia(id) {
  return axios
    .get(`${MEDIA_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createMedia(
  title,
  titleEN,
  file,
  mediaTime,
  field1,
  field1EN,
  field2,
  field2EN,
  field3,
  field3EN
) {
  const formData = new FormData();
  formData.append("title", title);
  formData.append("titleEN", titleEN);
  formData.append("file", file);
  formData.append("mediaTime", mediaTime);
  formData.append("AdditionalField1", field1);
  formData.append("AdditionalField1EN", field1EN);
  formData.append("AdditionalField2", field2);
  formData.append("AdditionalField2EN", field2EN);
  formData.append("AdditionalField3", field3);
  formData.append("AdditionalField3EN", field3EN);
  formData.append("userId", userId);

  return axios
    .post(MEDIA_CREATE, formData, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updateMedia(
  id,
  title,
  titleEN,
  file,
  mediaTime,
  field1,
  field1EN,
  field2,
  field2EN,
  field3,
  field3EN
) {
  const formData = new FormData();
  formData.append("title", title);
  formData.append("titleEN", titleEN);
  formData.append("file", file);
  formData.append("mediaTime", mediaTime);
  formData.append("AdditionalField1", field1);
  formData.append("AdditionalField1EN", field1EN);
  formData.append("AdditionalField2", field2);
  formData.append("AdditionalField2EN", field2EN);
  formData.append("AdditionalField3", field3);
  formData.append("AdditionalField3EN", field3EN);
  formData.append("userId", userId);
  formData.append("mediaID", id);

  return axios
    .post(`${MEDIA_UPDATE}/${id}`, formData, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deleteMedia(id) {
  return axios
    .delete(`${MEDIA_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// Media END

// Popup
export function getAllPopups() {
  return axios
    .get(POPUP_GETALL, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getPopup(id) {
  return axios
    .get(`${POPUP_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createPopup(
  title,
  titleEN,
  description,
  descriptionEN,
  content,
  contentEN,
  imageGroupID
) {
  return axios
    .post(
      POPUP_CREATE,
      {
        title: title,
        titleEN: titleEN,
        description: description,
        descriptionEN: descriptionEN,
        content: content,
        contentEN: contentEN,
        imageGroupID: imageGroupID,
        userId: userId,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updatePopup(
  id,
  title,
  titleEN,
  description,
  descriptionEN,
  content,
  contentEN,
  imageGroupID
) {
  return axios
    .put(
      `${POPUP_UPDATE}/${id}`,
      {
        title: title,
        titleEN: titleEN,
        description: description,
        descriptionEN: descriptionEN,
        content: content,
        contentEN: contentEN,
        imageGroupID: imageGroupID,
        userId: userId,
        popupID: id,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deletePopup(id) {
  return axios
    .delete(`${POPUP_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// Popup END

// SocialMedia
export function getAllSocialMedias() {
  return axios
    .get(SOCIALMEDIA_GETALL, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getSocialMedia(id) {
  return axios
    .get(`${SOCIALMEDIA_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createSocialMedia(file, SocialMediaTitle, SocialMediaUrl) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("SocialMediaTitle", SocialMediaTitle);
  formData.append("SocialMediaUrl", SocialMediaUrl);
  formData.append("UserId", userId);

  return axios
    .post(SOCIALMEDIA_CREATE, formData, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deleteSocialMedia(id) {
  return axios
    .delete(`${SOCIALMEDIA_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// SocialMedia END

// Product
export function getAllProducts(search, page) {
  return axios
    .get(
      `${PRODUCT_GETALL}?searchTerm=${search}&pageNumber=${page}&pageSize=25`,
      header
    )
    .then((res) => {
      const responseHeaders = res.headers;
      const pagination = JSON.parse(responseHeaders[`x-pagination`]);

      return {
        data: res.data,
        pagination: pagination || {},
      };
    })
    .catch((er) => {
      toast.error(er.message);
      return { data: [], pagination: {} };
    });
}

export function getAllProductsSlider() {
  return axios
    .get(`${PRODUCT_GETALLSLİDER}`, header)
    .then((res) => res.data)
    .catch((er) => {
      toast.error(er.message);
      return { message: "Error", result: [] }; // Hata durumunda boş array dön
    });
}

export function getProduct(id) {
  return axios
    .get(`${PRODUCT_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createProduct(
  productName,
  productNameEN,
  shortDescription,
  shortDescriptionEN,
  content,
  contentEN,
  price,
  discount,
  discountedPrice,
  additionalSpace1,
  additionalSpace1EN,
  additionalSpace2,
  additionalSpace2EN,
  additionalSpace3,
  additionalSpace3EN,
  barcodeNo,
  lotNo,
  refNo,
  brandId,
  imageGroupID,
  unpublicfileId,
  categoryId
) {
  return axios
    .post(
      PRODUCT_CREATE,
      {
        productName: productName,
        productNameEN: productNameEN,
        shortDescription: shortDescription,
        shortDescriptionEN: shortDescriptionEN,
        content: content,
        contentEN: contentEN,
        price: price,
        discount: discount,
        discountedPrice: discountedPrice,
        additionalSpace1: additionalSpace1,
        additionalSpace1EN: additionalSpace1EN,
        additionalSpace2: additionalSpace2,
        additionalSpace2EN: additionalSpace2EN,
        additionalSpace3: additionalSpace3,
        additionalSpace3EN: additionalSpace3EN,
        stockNo: 0,
        barcodeNo: barcodeNo,
        lotNo: lotNo,
        refNo: refNo,
        brandId: brandId,
        stock: 0,
        unPublicFileGroupId: null,
        imageGroupID: imageGroupID,
        unitId: null,
        mediaId: null,
        unPublicFileID: unpublicfileId,
        categoryId: categoryId,
        userId: userId,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
export function createProductN(
  productName,
  productNameEN,
  shortDescription,
  shortDescriptionEN,
  content,
  contentEN,
  price,
  discount,
  discountedPrice,
  additionalSpace1,
  additionalSpace1EN,
  additionalSpace2,
  additionalSpace2EN,
  additionalSpace3,
  additionalSpace3EN,
  barcodeNo,
  lotNo,
  refNo,
  brandId,
  imageGroupID,
  unpublicfileId,
  categoryId,
  files=[]
) {

    const formData = new FormData();
    formData.append("ProductName", productName);
    formData.append("ProductNameEN", productNameEN);
    formData.append("ShortDescription", shortDescription);
    formData.append("ShortDescriptionEN", shortDescriptionEN);
    formData.append("Content", content);
    formData.append("ContentEN", contentEN);
    formData.append("Price", price);
    formData.append("Discount", discount);
    formData.append("DiscountedPrice", discountedPrice);
    formData.append("AdditionalSpace1", additionalSpace1);
    formData.append("AdditionalSpace1EN", additionalSpace1EN);
    formData.append("AdditionalSpace2", additionalSpace2);
    formData.append("AdditionalSpace2EN", additionalSpace2EN);
    formData.append("AdditionalSpace3", additionalSpace3);
    formData.append("AdditionalSpace3EN", additionalSpace3EN);
    formData.append("BarcodeNo", barcodeNo);
    formData.append("LotNo", lotNo);
    formData.append("RefNo", refNo);
    formData.append("BrandId", brandId);
    if(imageGroupID != null && imageGroupID != undefined){
        formData.append("ImageGroupID", imageGroupID);
    }
    if(unpublicfileId != null && unpublicfileId != undefined){
        formData.append("UnPublicFileID", unpublicfileId);
    }
    formData.append("CategoryId", categoryId);
    formData.append("UserId", userId);

    if (files && files.length > 0) {
        files.forEach((file) => {
          formData.append("file", file); 
        });
      }
  return axios
    .post(
      PRODUCT_CREATE,formData,{
        ...header,
        headers: {
            ...header.headers,
            "Content-Type": "multipart/form-data",
            },
      }    
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createProductExcelImport(file) {
  const formData = new FormData();
  formData.append("file", file);

  return axios
    .post(`${PRODUCT_EXCELIMPORT}/${userId}`, formData, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updateProduct(
  id,
  productName,
  productNameEN,
  shortDescription,
  shortDescriptionEN,
  content,
  contentEN,
  price,
  discount,
  discountedPrice,
  additionalSpace1,
  additionalSpace1EN,
  additionalSpace2,
  additionalSpace2EN,
  additionalSpace3,
  additionalSpace3EN,
  barcodeNo,
  lotNo,
  refNo,
  brandId,
  imageGroupID,
  unpublicfileId,
  categoryId,
  isSlider
) {
  return axios
    .put(
      `${PRODUCT_UPDATE}/${id}`,
      {
        productName: productName,
        productNameEN: productNameEN,
        shortDescription: shortDescription,
        shortDescriptionEN: shortDescriptionEN,
        content: content,
        contentEN: contentEN,
        price: price,
        discount: discount,
        discountedPrice: discountedPrice,
        additionalSpace1: additionalSpace1,
        additionalSpace1EN: additionalSpace1EN,
        additionalSpace2: additionalSpace2,
        additionalSpace2EN: additionalSpace2EN,
        additionalSpace3: additionalSpace3,
        additionalSpace3EN: additionalSpace3EN,
        stockNo: 0,
        barcodeNo: barcodeNo,
        lotNo: lotNo,
        refNo: refNo,
        brandId: brandId,
        stock: 0,
        unPublicFileGroupId: null,
        imageGroupID: imageGroupID,
        unitId: null,
        mediaId: null,
        unPublicFileID: unpublicfileId,
        categoryId: categoryId,
        userId: userId,
        productID: id,
        isSlider: isSlider,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
export function updateProductN(
    id,
    productName,
    productNameEN,
    shortDescription,
    shortDescriptionEN,
    content,
    contentEN,
    price,
    discount,
    discountedPrice,
    additionalSpace1,
    additionalSpace1EN,
    additionalSpace2,
    additionalSpace2EN,
    additionalSpace3,
    additionalSpace3EN,
    barcodeNo,
    lotNo,
    refNo,
    brandId,
    imageGroupID,
    unpublicfileId,
    categoryId,
    isSlider,
    files
  ) {
      const formData = new FormData();
      formData.append("ProductName", productName);
      formData.append("ProductNameEN", productNameEN);
      formData.append("ShortDescription", shortDescription);
      formData.append("ShortDescriptionEN", shortDescriptionEN);
      formData.append("Content", content);
      formData.append("ContentEN", contentEN);
      formData.append("Price", price);
      formData.append("Discount", discount);
      formData.append("DiscountedPrice", discountedPrice);
      formData.append("AdditionalSpace1", additionalSpace1);
      formData.append("AdditionalSpace1EN", additionalSpace1EN);
      formData.append("AdditionalSpace2", additionalSpace2);
      formData.append("AdditionalSpace2EN", additionalSpace2EN);
      formData.append("AdditionalSpace3", additionalSpace3);
      formData.append("AdditionalSpace3EN", additionalSpace3EN);
      formData.append("BarcodeNo", barcodeNo);
      formData.append("LotNo", lotNo);
      formData.append("RefNo", refNo);
      if(brandId != null && brandId != undefined){
          formData.append("BrandId", brandId);
      }
      if(imageGroupID != null && imageGroupID != undefined){
          formData.append("ImageGroupID", imageGroupID);
      }
      if(unpublicfileId != null && unpublicfileId != undefined){
        formData.append("UnPublicFileID", unpublicfileId);
    }
    if(categoryId != null && categoryId != undefined){
        formData.append("CategoryId", categoryId);
    }
      formData.append("UserId", userId);
      formData.append("ProductID", id);
      formData.append("IsSlider", isSlider);
  
      if (files && files.length > 0) {
          files.forEach((file) => {
            formData.append("file", file); 
          });
        }
  
    return axios
      .put(
        PRODUCT_UPDATE,formData,{
          ...header,
          headers: {
              ...header.headers,
              "Content-Type": "multipart/form-data",
              },
        }      
      )
      .then((res) => res.data)
      .catch((er) => toast.error(er));
  }

export function deleteProduct(id) {
  return axios
    .delete(`${PRODUCT_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// Product END

// ProductCategory
export function getAllProductCategorys(search) {
  return axios
    .get(PRODUCTCATEGORY_GETALL, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getAllProductsFilter(
  searchTerm = "",
  pageNumber = 1,
  pageSize = 25
) {
  return axios
    .get(
      `${PRODUCT_GETALL}?SearchTerm=${encodeURIComponent(
        searchTerm
      )}&PageNumber=${pageNumber}&PageSize=${pageSize}`,
      header
    )
    .then((res) => {
      console.log("Products Response:", res.data);
      return res.data;
    })
    .catch((error) => {
      console.error("Products Error:", error);
      throw error;
    });
}

export function geAllProductsFilterH(
  searchTerm = "",
  pageNumber = 1,
  pageSize = 25
) {
  return axios
    .get(
      `${PRODUCT_GETALL}?SearchTerm=${encodeURIComponent(
        searchTerm
      )}&PageNumber=${pageNumber}&PageSize=${pageSize}`,
      header
    )
    .then((res) => {
      const responseHeaders = res.headers;
      const pagination = JSON.parse(responseHeaders[`x-pagination`]);

      return {
        data: res.data,
        pagination: pagination || {},
      };
    })
    .catch((error) => {
      console.error("Products Error:", error);
      throw error;
    });
}

export function getProductCategory(id) {
  return axios
    .get(`${PRODUCTCATEGORY_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createProductCategory(categoryID, productID) {
  return axios
    .post(
      PRODUCTCATEGORY_CREATE,
      { categoryID: categoryID, productID: productID, userId: userId },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updateProductCategory(id, categoryID, productID) {
  return axios
    .put(
      `${PRODUCTCATEGORY_UPDATE}/${id}`,
      {
        categoryID: categoryID,
        productID: productID,
        userId: userId,
        productID: id,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deleteProductCategory(id) {
  return axios
    .delete(`${PRODUCTCATEGORY_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// ProductCategory END

// Promotion
export function getAllPromotions() {
  return axios
    .get(PROMOTION_GETALL, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getPromotion(id) {
  return axios
    .get(`${PROMOTION_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createPromotion(
  title,
  titleEN,
  description,
  descriptionEN,
  imageGroupID
) {
  return axios
    .post(
      PROMOTION_CREATE,
      {
        title: title,
        titleEN: titleEN,
        description: description,
        descriptionEN: descriptionEN,
        settingID: 1,
        imageGroupID: imageGroupID,
        userId: userId,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updatePromotion(
  id,
  title,
  titleEN,
  description,
  descriptionEN,
  imageGroupID
) {
  return axios
    .put(
      `${PROMOTION_UPDATE}/${id}`,
      {
        title: title,
        titleEN: titleEN,
        description: description,
        descriptionEN: descriptionEN,
        settingID: 1,
        imageGroupID: imageGroupID,
        userId: userId,
        promotionID: id,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deletePromotion(id) {
  return axios
    .delete(`${PROMOTION_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// Promotion END

// Setting
export function getAllSettings() {
  return axios
    .get(SETTING_GETALL, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getSetting(id) {
  return axios
    .get(`${SETTING_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createSetting(
  appName,
  description,
  additionalField1,
  additionalField2,
  additionalField3,
  about,
  termsOfUse,
  security,
  cookiesPolicy,
  corporateInformation,
  imageGroupId
) {
  return axios
    .post(
      SETTING_CREATE,
      {
        appName: appName,
        description: description,
        additionalField1: additionalField1,
        additionalField2: additionalField2,
        additionalField3: additionalField3,
        about: about,
        termsOfUse: termsOfUse,
        security: security,
        cookiesPolicy: cookiesPolicy,
        corporateInformation: corporateInformation,
        imageGroupID: imageGroupId,
        userId: userId,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updateSetting(
  id,
  appName,
  appNameEN,
  description,
  descriptionEN,
  additionalField1,
  additionalField1EN,
  additionalField2,
  additionalField2EN,
  additionalField3,
  additionalField3EN,
  about,
  aboutEN,
  termsOfUse,
  termsOfUseEN,
  security,
  securityEN,
  cookiesPolicy,
  cookiesPolicyEN,
  corporateInformation,
  corporateInformationEN,
  activeUser,
  imageGroupId
) {
  return axios
    .put(
      `${SETTING_UPDATE}/${id}`,
      {
        appName: appName,
        appNameEN: appNameEN,
        description: description,
        descriptionEN: descriptionEN,
        additionalField1: additionalField1,
        additionalField1EN: additionalField1EN,
        additionalField2: additionalField2,
        additionalField2EN: additionalField2EN,
        additionalField3: additionalField3,
        additionalField3EN: additionalField3EN,
        about: about,
        aboutEN: aboutEN,
        termsOfUse: termsOfUse,
        termsOfUseEN: termsOfUseEN,
        security: security,
        securityEN: securityEN,
        cookiesPolicy: cookiesPolicy,
        cookiesPolicyEN: cookiesPolicyEN,
        corporateInformation: corporateInformation,
        corporateInformationEN: corporateInformationEN,
        imageGroupID: imageGroupId,
        activeUserCount: parseInt(activeUser),
        userId: userId,
        settingID: id,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deleteSetting(id) {
  return axios
    .delete(`${SETTING_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// Setting END

// Contact
export function getContact(id) {
  return axios
    .get(`${CONTACT_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updateContact(
  id,
  email,
  email1,
  email2,
  email3,
  phone,
  phone1,
  phone2,
  phone3,
  address,
  address1,
  address2,
  address3,
  location,
  location1,
  location2,
  location3,
  content,
  contentEN
) {
  return axios
    .put(
      `${CONTACT_UPDATE}/${id}`,
      {
        email: email,
        email1: email1,
        email2: email2,
        email3: email3,
        phone: phone,
        phone1: phone1,
        phone2: phone2,
        phone3: phone3,
        address: address,
        address1: address1,
        address2: address2,
        address3: address3,
        location: location,
        location1: location1,
        location2: location2,
        location3: location3,
        content: content,
        contentEN: contentEN,
        userId: userId,
        contactId: id,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// Contact END

// SSS
export function getAllSSSs() {
  return axios
    .get(SSS_GETALL, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getSSS(id) {
  return axios
    .get(`${SSS_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function createSSS(title, titleEN, description, descriptionEN) {
  return axios
    .post(
      SSS_CREATE,
      {
        title: title,
        titleEN: titleEN,
        description: description,
        descriptionEN: descriptionEN,
        userId: userId,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updateSSS(id, title, titleEN, description, descriptionEN) {
  return axios
    .put(
      `${SSS_UPDATE}/${id}`,
      {
        title: title,
        titleEN: titleEN,
        description: description,
        descriptionEN: descriptionEN,
        userId: userId,
        sssID: id,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deleteSSS(id) {
  return axios
    .delete(`${SSS_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// SSS END

// User
export function getAllUsers(search) {
  return axios
    .get(
      `${USER_GETALL}?searchTerm=${search}&pageNumber=1&pageSize=100`,
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function getUser(id) {
  return axios
    .get(`${USER_GET}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function updateUser(
  id,
  firstName,
  lastName,
  userName,
  email,
  phoneNumber
) {
  return axios
    .put(
      `${USER_UPDATE}/${id}`,
      {
        firstName: firstName,
        lastName: lastName,
        userName: userName,
        email: email,
        phoneNumber: phoneNumber,
        userId: id,
      },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function changePassword(id, currentPassword, newPassword) {
  return axios
    .put(
      `${USER_CHANGEPASSWORD}/${id}`,
      { currentPassword: currentPassword, newPassword: newPassword },
      header
    )
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function changePoint(id, point) {
  return axios
    .put(`${USER_CHANGEPASSWORD}/${id}`, { userId: id, point: point }, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}

export function deleteUser(id) {
  return axios
    .delete(`${USER_DELETE}/${id}`, header)
    .then((res) => res.data)
    .catch((er) => toast.error(er));
}
// User END
